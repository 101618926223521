import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-fund-approval-accompaniment',
  templateUrl: './dashboard-fund-approval-accompaniment.component.html',
  styleUrls: ['./dashboard-fund-approval-accompaniment.component.scss'],
})
export class DashboardFundApprovalAccompanimentComponent implements OnInit {
  loading = true;
  data;

  columns: TableColumnList<UserApprovalSignature>[] = [
    {
      name: 'Fase',
      cell: (row) =>
        row.department_approval.type === 'department'
          ? row.department_approval.department.name
          : row.department_approval.agent.name,
    },
    {
      name: 'Usuário',
      cell: (row) => {
        const is_person = row.register.person !== null;
        let name = is_person
          ? row.register.person.full_name
          : row.register.company.corporate_name;

        return `
        <span>${name}</span>
        `;
        // <span class="app-table-details-body-row-subtitle">
        // ${row.register.type_display}
        // </span>
      },
      size: 2,
    },
    {
      name: 'Ação',
      cell: (row) =>
        row.approved ? 'Aprovado' : row.restart ? 'Retornado' : 'Reprovado',
    },
    {
      name: 'Data',
      cell: (row) => this.formateDate(row.date),
    },
    {
      name: 'Observação',
      cell: (row) => row.reason,
      size: 2,
    },
    // {
    //   name: '',
    //   cell: (row) =>
    //     `<div class="icon-container"><i class="fas fa-eye ml-auto"></i></div> `,
    // },
  ];

  constructor(
    private router: Router,
    private fundService: FundService,
    private api: ApiService,
    private toast: ToastService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.fundService.fundData.signatures) {
      this.data = this.fundService.fundData.signatures;
      this.loading = false;
    } else {
      this.getFundData();
    }
  }

  handleText(text: string) {
    if (text.length > 40) {
      const shortText = text.substring(0, 40);
      return `${shortText}...`;
    }
    return text;
  }

  redirectTo(path: string) {
    this.router.navigateByUrl(path);
  }

  formateDate(date: string) {
    const day = date.split('T').shift()?.split('-').reverse().join('/');
    const hours = date.split('T').pop()?.slice(0, 5);

    return `${day} às ${hours}`;
  }

  async getFundData() {
    try {
      const data = await this.api.get({
        route: 'api/v2/approvals/version/',
        params: {
          fund: this.route.snapshot.params.id,
          tab: 'history',
        },
        token: true,
      });

      const fund = { ...data };

      if (fund[0].fund && fund[0].fund.length === 0) {
        this.loading = false;
        return;
      }

      this.data = fund[0].fund[0].signatures;

      this.fundService.fundData = {
        ...this.fundService.fundData,
        signatures: fund[0].fund[0].signatures,
      };
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao carregar histórico: ' + error);
    }

    this.loading = false;
  }
}
