
const gitInfo = {
  branch: "master",
  commit: "bc405c2",
  origin: "https://bitbucket.org/prosperitainova/dataengine-web-angular",
  url: "https://bitbucket.org/prosperitainova/dataengine-web-angular/commits/bc405c2d8dc6dd2bde3680fccae3f68f700e2cef",
  date: "18/03/2025",
};

export default gitInfo;
  