import { EventEmitter, Injectable } from '@angular/core';

import { StorageService } from '../storage/storage.service';
import { ToastService } from '../toast/toast.service';
import { Router } from '@angular/router';

export type UserTypes = 'PF' | 'PJ' | 'ADM' | '';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token: string | undefined = undefined;
  _user: NewLoginResponse | undefined = undefined;

  activeUser: NewLoginResponse | undefined = undefined;
  approvalData = {
    attachmentsData: null,
    attachmentsRepresentatives: null,
    representative: null,
    signs: null,
    risk: null,
    composition: null,
    complementary_data: null,
    powers: null,
    history: null,
    signatures: null,
    service_providers: null,
    professional: null,
    income: null,
    version: null,
    revision: null,
  };

  userAuth: 'authStep' | 'recoveryStep' = 'authStep';
  code: string = '0000';

  userTypePermission: string = '';
  forgotEmail: string = '';
  cpfAuthenticate: boolean = false;
  hidedForgotEmail: string = '';
  forgotValue: string = '';
  permissions: PermissionValue[] = [];
  navigationPermissions: MenuItem[] = [];
  accountsArr: RegisterUserResponse[] = [];

  finances: FinancePFResponse | undefined;
  userChange = new EventEmitter<NewLoginResponse | undefined>();
  financesFetched = new EventEmitter<FinancePFResponse>();
  registerRefetchEvent = new EventEmitter();

  //   TODO - mudar para outro service
  reloadApprovalEvent = new EventEmitter();
  menuLoadedEvent = new EventEmitter();
  reloadComplementaryDataEvent = new EventEmitter();
  changeVersion = new EventEmitter();
  permissionsLoadedEvent = new EventEmitter();

  get user() {
    return this._user;
  }

  set user(user: NewLoginResponse | undefined) {
    this._user = user;
    this.userChange.emit(user);
  }

  constructor(
    private storageService: StorageService,
    private toast: ToastService,
    private router: Router
  ) {
    const user = this.storageService.getUser();
    if (user) this.user = user;

    const token = this.storageService.getToken();
    if (token) this.token = token;
  }

  setToken(token: string) {
    this.storageService.setToken(token);
    this.token = token;
  }

  getToken(): string | undefined {
    if (this.token) {
      return this.token;
    }

    const storedToken = this.storageService.getToken();

    if (storedToken) {
      this.token = storedToken;
      return this.token;
    }

    return undefined;
  }

  setUser(user: NewLoginResponse) {
    this.storageService.setUser(user);
    this.user = user;
  }

  updateUser(user: Partial<NewLoginResponse>) {
    const newUser: NewLoginResponse = Object.assign(this.user, user);
    this.setUser(newUser);
  }

  getUser(): NewLoginResponse | undefined {
    if (this.user) {
      return this.user;
    }

    const storedUser = this.storageService.getUser();

    if (storedUser) {
      this.user = storedUser;
      return storedUser;
    }

    return undefined;
  }

  setPermissionType(type: string) {
    this.storageService.setPermissionType(type);
    this.userTypePermission = type;
  }

  getPermissionType() {
    if (this.userTypePermission) {
      return this.userTypePermission;
    }

    const storedType = this.storageService.getPermissionType();

    if (storedType) {
      this.userTypePermission = storedType;
      return storedType;
    }

    return '';
  }

  setActiveUser(user: NewLoginResponse) {
    this.activeUser = user;
  }

  getActiveUser(): NewLoginResponse | undefined {
    return this.activeUser;
  }

  setUserAuth(step: 'authStep' | 'recoveryStep', code: string) {
    this.userAuth = step;
    this.code = code;
  }

  getUserAuth() {
    return this.userAuth;
  }

  clearUser() {
    this.user = undefined;
    this.token = undefined;
    this.storageService.logout();
  }

  canViewPage(condition: string[]) {
    if (!this.verifyPermission(condition)) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );

      this.router.navigateByUrl('/app/dashboard');
    }
  }

  verifyPermission = (codiname: string | string[] = []) => {
    const search = typeof codiname === 'string' ? [codiname] : codiname;

    const mappedPerms = this.permissions
      ? this.permissions.map((permission) => permission.codename)
      : [];

    const hasPerm = search.every((perm) => mappedPerms.includes(perm));

    return hasPerm;
  };

  reset() {
    this.approvalData = {
      attachmentsData: null,
      attachmentsRepresentatives: null,
      representative: null,
      signs: null,
      composition: null,
      complementary_data: null,
      powers: null,
      history: null,
      service_providers: null,
      signatures: null,
      professional: null,
      risk: null,
      income: null,
      version: null,
      revision: null,
    };
  }
}
