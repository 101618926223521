<div class="row">
    <div class="col-12">
        <div class="dash-card card-padding-top">
            <app-empty-content [isVisible]="data.length === 0"
                title="Nenhuma minuta encontrada"
                description="Não foi encontrada nenhuma minuta nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png"
                buttonLabel="Criar template" (onClick)="triggerModal()">
            </app-empty-content>

            <table class="bordered" *ngIf="data.length > 0">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Destino</th>
                        <th>Última atualização</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of data">
                        <td>{{item.file}}</td>
                        <td>{{formatApplicable(item.applicable)}}</td>
                        <td>{{formatDate(item.date_updated)}}</td>
                        <td>
                            <div class="dash-card-item-buttons">
                                <button class="dash-item-button"
                                    (click)="handleDelete(item.id)">
                                    <i class="fas fa-trash"></i>
                                </button>
                                <app-spacer [right]="16"></app-spacer>

                                <button class="dash-item-button"
                                    (click)="handleEdit(item.id)">
                                    <i class="fas fa-pen"></i>
                                </button>
                                <app-spacer [right]="16"></app-spacer>

                                <button class="dash-item-button"
                                    (click)="handleOpenVisualizer(item.id)">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <app-spacer [bottom]="28"></app-spacer>
            </table>
        </div>
        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>

<app-side-modal [active]="showModal"
    [title]="selectedDraft ? 'Atualizar minuta' : 'Adicionar minuta'"
    (onClose)="closeModal()" paddingBottom="30">
    <form [formGroup]="form">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <app-select [items]="funds" label="Selecione um fundo"
                        controlName="fund" [group]="form">
                    </app-select>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <editor
                        apiKey="xxjfy3dzhyh1jz81cf1gk8j7gw0gt7rdpses3plaegf89jv6"
                        [init]="config" class="editor-container"
                        formControlName="content">
                    </editor>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>

            <div class="variable-container">
                <h4>Variáveis disponíveis</h4>

                <div class="row">
                    <div class="col-12" *ngFor="let item of documentation">
                        <app-collapse [title]="item.name">
                            <ul [ngbCollapse]="">
                                <li *ngFor="let child of item.children">
                                    {{child}}
                                </li>
                            </ul>
                        </app-collapse>

                        <app-spacer [bottom]="16"></app-spacer>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="row button-sticky">
                <div class="col-12 col-lg-6">
                    <app-button (onClick)="closeModal()" class="add-button"
                        [outline]="true">
                        Cancelar
                    </app-button>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="text-right">
                        <app-button (onClick)="createTemplate()"
                            [disabled]="getDisabled()" class="add-button">
                            {{selectedDraft ? 'Atualizar template' : 'Criar
                            template'}}
                        </app-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>