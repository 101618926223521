<div class="col-12" *ngIf="loading">
    <div class="row">
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{
                        height: '244px',
                        margin:'0 0 24px'
                    }">
            </ngx-skeleton-loader>
        </div>
    </div>
</div>

<div class="row" *ngIf="!loading">
    <div class="col-12">
        <div class="dash-details-card">
            <app-empty-content [isVisible]="!data"
                title="Nenhum dado encontrado"
                description="Não foi encontrado nenhum dado de risco nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">
            </app-empty-content>

            <ng-container *ngIf="data">
                <div class="dash-details-card-header"></div>
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-3">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Declarado
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{renderLabel(data?.qualification)}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-3">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Se declara PEP?
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data?.politically_exposed
                                    ? 'Sim' : 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Autoriza a transmissão de ordens por
                                    procurador ou representante?
                                </span>
                                <span class="dash-details-card-item-title">
                                    {{data?.authorized_attorney_representative
                                    ? 'Sim' : 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <app-spacer [bottom]="16"></app-spacer>

        <ng-container *ngIf="pepData && pepData.length">
            <div class="dash-details-card">
                <div class="dash-details-card-header">
                    <span>
                        Possui relacionamento com PEP
                    </span>
                </div>
                <app-spacer [bottom]="25"></app-spacer>

                <app-table-list [columns]="pepColumns" [data]="pepData">
                </app-table-list>
            </div>
            <app-spacer [bottom]="16"></app-spacer>
        </ng-container>

        <div class="dash-details-card">
            <app-empty-content [isVisible]="!data"
                title="Nenhum dado encontrado"
                description="Não foi encontrado nenhum dado de risco nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">
            </app-empty-content>

            <ng-container *ngIf="data">
                <div class="dash-details-card-header"></div>
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Opera por conta de terceiros?
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data?.third_party_operation
                                    ? 'Sim' : 'Não'}}

                                    <ng-container
                                        *ngIf="data?.third_party_operation">
                                        -
                                        {{data?.document_number_person_or_company
                                        ?? '--'}}
                                    </ng-container>

                                    <ng-container
                                        *ngIf="data?.third_party_operation">
                                        | {{data?.name_or_company_name
                                        ?? '--'}}
                                    </ng-container>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Possuí vínculo com a Banvox?
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data?.linked_cm_capital
                                    ? 'Sim' : 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>