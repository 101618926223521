import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';

import { AngularSvgIconModule, SvgIconComponent } from 'angular-svg-icon';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ButtonComponent } from './components/button/button.component';
import { ComponentsComponent } from './pages/components/components.component';
import { DropzoneComponent } from './components/dropzone/dropzone.component';
import { InputComponent } from './components/input/input.component';
import { LoginComponent } from './pages/login/login.component';
import { RadioComponent } from './components/radio/radio.component';
import { SelectComponent } from './components/select/select.component';
import { SpacerComponent } from './components/spacer/spacer.component';
import { TableComponent } from './components/table/table.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ToastComponent } from './components/toast/toast.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ValidateComponent } from './pages/validate/validate.component';
import { ChoicesComponent } from './components/choices/choices.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { ContainerComponent } from './components/container/container.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';

import { SignupStatusComponent } from './pages/signup-status/signup-status.component';

import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { AttributeDirective } from './directives/attribute/attribute.directive';

import { NgxCurrencyModule } from 'ngx-currency';
import { CurrencyInputComponent } from './components/currency-input/currency-input.component';
import { MaskedInputComponent } from './components/masked-input/masked-input.component';
import { DashSidebarComponent } from './components/dash-sidebar/dash-sidebar.component';

import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { DashboardShareholderComponent } from './pages/dashboard-shareholder/dashboard-shareholder.component';
import { DashboardShareholderDeclarationsComponent } from './pages/dashboard-shareholder/dashboard-shareholder-declarations/dashboard-shareholder-declarations.component';
import { DashboardShareholderConditionsComponent } from './pages/dashboard-shareholder/dashboard-shareholder-conditions/dashboard-shareholder-conditions.component';
import { DashboardShareholderTaxComponent } from './pages/dashboard-shareholder/dashboard-shareholder-tax/dashboard-shareholder-tax.component';

import { DashboardAccountBasicComponent } from './pages/dashboard-account-basic/dashboard-account-basic.component';

import { DashboardSecurityComponent } from './pages/dashboard-security/dashboard-security.component';
import { DashboardOrganizationComponent } from './pages/dashboard-organization/dashboard-organization.component';

import { SideModalComponent } from './components/side-modal/side-modal.component';
import { DashboardSettingsComponent } from './pages/dashboard-settings/dashboard-settings.component';
import { DashboardAgentsComponent } from './pages/dashboard-agents/dashboard-agents.component';
import { DashboardContributorsComponent } from './pages/dashboard-contributors/dashboard-contributors.component';
import { DashboardDocumentsComponent } from './pages/dashboard-documents/dashboard-documents.component';
import { LoadingComponent } from './components/loading/loading.component';

import { FormErrorIndicatorComponent } from './components/form-error-indicator/form-error-indicator.component';

import { DashboardPersonComponent } from './pages/dashboard-person/dashboard-person.component';
import { DashboardPersonAnalysisComponent } from './pages/dashboard-person/dashboard-person-analysis/dashboard-person-analysis.component';
import { DashboardPersonAttachmentsComponent } from './pages/dashboard-person/dashboard-person-attachments/dashboard-person-attachments.component';
import { DashboardPersonComplementaryComponent } from './pages/dashboard-person/dashboard-person-complementary/dashboard-person-complementary.component';
import { DashboardPersonConditionsComponent } from './pages/dashboard-person/dashboard-person-conditions/dashboard-person-conditions.component';
import { DashboardPersonDeclarationsComponent } from './pages/dashboard-person/dashboard-person-declarations/dashboard-person-declarations.component';
import { DashboardPersonIncomeComponent } from './pages/dashboard-person/dashboard-person-income/dashboard-person-income.component';
import { DashboardPersonTaxComponent } from './pages/dashboard-person/dashboard-person-tax/dashboard-person-tax.component';
import { DashboardPersonTermsComponent } from './pages/dashboard-person/dashboard-person-terms/dashboard-person-terms.component';
import { DashboardPersonComplementaryAddressComponent } from './pages/dashboard-person/dashboard-person-complementary/dashboard-person-complementary-address/dashboard-person-complementary-address.component';
import { DashboardPersonComplementaryBankComponent } from './pages/dashboard-person/dashboard-person-complementary/dashboard-person-complementary-bank/dashboard-person-complementary-bank.component';
import { DashboardPersonComplementaryGeneralComponent } from './pages/dashboard-person/dashboard-person-complementary/dashboard-person-complementary-general/dashboard-person-complementary-general.component';
import { DashboardPersonComplementaryProfessionalComponent } from './pages/dashboard-person/dashboard-person-complementary/dashboard-person-complementary-professional/dashboard-person-complementary-professional.component';
import { DashboardPersonComplementaryRepresentativesComponent } from './pages/dashboard-person/dashboard-person-complementary/dashboard-person-complementary-representatives/dashboard-person-complementary-representatives.component';
import { DashboardPersonIncomeOthersComponent } from './pages/dashboard-person/dashboard-person-income/dashboard-person-income-others/dashboard-person-income-others.component';
import { DashboardPersonIncomeRealtyComponent } from './pages/dashboard-person/dashboard-person-income/dashboard-person-income-realty/dashboard-person-income-realty.component';
import { DashboardPersonIncomeSalaryComponent } from './pages/dashboard-person/dashboard-person-income/dashboard-person-income-salary/dashboard-person-income-salary.component';
import { DashboardCompanyAnalysisComponent } from './pages/dashboard-company/dashboard-company-analysis/dashboard-company-analysis.component';
import { DashboardCompanyAttachmentsComponent } from './pages/dashboard-company/dashboard-company-attachments/dashboard-company-attachments.component';
import { DashboardCompanyComplementaryComponent } from './pages/dashboard-company/dashboard-company-complementary/dashboard-company-complementary.component';
import { DashboardCompanyConditionsComponent } from './pages/dashboard-company/dashboard-company-conditions/dashboard-company-conditions.component';
import { DashboardCompanyDeclarationsComponent } from './pages/dashboard-company/dashboard-company-declarations/dashboard-company-declarations.component';
import { DashboardCompanyIncomeComponent } from './pages/dashboard-company/dashboard-company-income/dashboard-company-income.component';
import { DashboardCompanyTaxComponent } from './pages/dashboard-company/dashboard-company-tax/dashboard-company-tax.component';
import { DashboardCompanyTermsComponent } from './pages/dashboard-company/dashboard-company-terms/dashboard-company-terms.component';
import { DashboardCompanyComplemenntaryAddressComponent } from './pages/dashboard-company/dashboard-company-complementary/dashboard-company-complemenntary-address/dashboard-company-complemenntary-address.component';
import { DashboardCompanyComplemenntaryBankComponent } from './pages/dashboard-company/dashboard-company-complementary/dashboard-company-complemenntary-bank/dashboard-company-complemenntary-bank.component';
import { DashboardCompanyComplemenntaryCompositionComponent } from './pages/dashboard-company/dashboard-company-complementary/dashboard-company-complemenntary-composition/dashboard-company-complemenntary-composition.component';
import { DashboardCompanyComplemenntaryEconomicComponent } from './pages/dashboard-company/dashboard-company-complementary/dashboard-company-complemenntary-economic/dashboard-company-complemenntary-economic.component';
import { DashboardCompanyComplemenntaryGeneralComponent } from './pages/dashboard-company/dashboard-company-complementary/dashboard-company-complemenntary-general/dashboard-company-complemenntary-general.component';
import { DashboardCompanyComplemenntaryRepresentativesComponent } from './pages/dashboard-company/dashboard-company-complementary/dashboard-company-complemenntary-representatives/dashboard-company-complemenntary-representatives.component';
import { DashboardCompanyIncomeOthersComponent } from './pages/dashboard-company/dashboard-company-income/dashboard-company-income-others/dashboard-company-income-others.component';
import { DashboardCompanyIncomeRealtyComponent } from './pages/dashboard-company/dashboard-company-income/dashboard-company-income-realty/dashboard-company-income-realty.component';
import { DashboardCompanyIncomeSalaryComponent } from './pages/dashboard-company/dashboard-company-income/dashboard-company-income-salary/dashboard-company-income-salary.component';
import { UpperCaseDirective } from './directives/Uppercase/upper-case.directive';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DashboardAdminComponent } from './pages/dashboard-admin/dashboard-admin.component';
import { LabelComponent } from './components/label/label.component';
import { DashboardCollaboratorsComponent } from './pages/dashboard-collaborators/dashboard-collaborators.component';
import { DashboardDepartmentsComponent } from './pages/dashboard-departments/dashboard-departments.component';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { DashboardApprovalComponent } from './pages/dashboard-approval/dashboard-approval.component';

import * as Sentry from '@sentry/angular';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SwitchComponent } from './components/switch/switch.component';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { DashboardAssignorComponent } from './pages/dashboard-assignor/dashboard-assignor.component';
import { WarningModalComponent } from './components/warning-modal/warning-modal.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { CookieModule } from 'ngx-cookie';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { DashboardFundsComponent } from './pages/dashboard-funds/dashboard-funds.component';
import { DashboardShareholdersComponent } from './pages/dashboard-shareholders/dashboard-shareholders.component';
import { DashboardAssignorsComponent } from './pages/dashboard-assignors/dashboard-assignors.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { SignatureCardComponent } from './components/signature-card/signature-card.component';
import { DashboardApprovalComplementaryComponent } from './pages/dashboard-approval/dashboard-approval-complementary/dashboard-approval-complementary.component';
import { TableListComponent } from './components/table-list/table-list.component';
import { DashboardApprovalCompositionComponent } from './pages/dashboard-approval/dashboard-approval-composition/dashboard-approval-composition.component';
import { DashboardApprovalRepresentativesComponent } from './pages/dashboard-approval/dashboard-approval-representatives/dashboard-approval-representatives.component';
import { DashboardApprovalAttachmentsComponent } from './pages/dashboard-approval/dashboard-approval-attachments/dashboard-approval-attachments.component';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { DashboardApprovalSignaturesComponent } from './pages/dashboard-approval/dashboard-approval-signatures/dashboard-approval-signatures.component';
import { DashboardApprovalAccompanimentComponent } from './pages/dashboard-approval/dashboard-approval-accompaniment/dashboard-approval-accompaniment.component';
import { DashboardApprovalDocumentsComponent } from './pages/dashboard-approval/dashboard-approval-documents/dashboard-approval-documents.component';
import { DocumentVisualizerModalComponent } from './components/document-visualizer-modal/document-visualizer-modal.component';
import { ButtonWithDropdownComponent } from './components/button-with-dropdown/button-with-dropdown.component';

import { DashboardFundApprovalComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval.component';
import { DashboardFundApprovalComplementaryComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-complementary/dashboard-fund-approval-complementary.component';
import { DashboardFundApprovalBasicComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-basic/dashboard-fund-approval-basic.component';
import { DashboardFundApprovalTransfersComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-transfers/dashboard-fund-approval-transfers.component';
import { DashboardFundApprovalProvidersComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-providers/dashboard-fund-approval-providers.component';
import { DashboardFundApprovalTaxComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-tax/dashboard-fund-approval-tax.component';
import { DashboardFundApprovalBankingComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-banking/dashboard-fund-approval-banking.component';
import { DashboardFundApprovalAttachmentsComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-attachments/dashboard-fund-approval-attachments.component';
import { DashboardFundApprovalDocumentsComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-documents/dashboard-fund-approval-documents.component';
import { DashboardFundApprovalShareholderComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-shareholder/dashboard-fund-approval-shareholder.component';
import { DashboardFundApprovalAssignorComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-assignor/dashboard-fund-approval-assignor.component';
import { DashboardSettingsAgentsComponent } from './pages/dashboard-settings/dashboard-settings-agents/dashboard-settings-agents.component';
import { DashboardSettingsDocumentsComponent } from './pages/dashboard-settings/dashboard-settings-documents/dashboard-settings-documents.component';
import { DashboardSettingsZeroingFundsComponent } from './pages/dashboard-settings/dashboard-settings-zeroing-funds/dashboard-settings-zeroing-funds.component';
import { DashboardZeroingFundsComponent } from './pages/dashboard-zeroing-funds/dashboard-zeroing-funds.component';
import { DashboardSettingsAuditComponent } from './pages/dashboard-settings/dashboard-settings-audit/dashboard-settings-audit.component';
import { DashboardAuditComponent } from './pages/dashboard-audit/dashboard-audit.component';
import { DashboardAdminAssignorsComponent } from './pages/dashboard-admin/dashboard-admin-assignors/dashboard-admin-assignors.component';
import { DashboardDocumentModelComponent } from './pages/dashboard-document-model/dashboard-document-model.component';
import { DashboardUserListComponent } from './pages/dashboard-user-list/dashboard-user-list.component';
import { DashboardApprovalPowersComponent } from './pages/dashboard-approval/dashboard-approval-powers/dashboard-approval-powers.component';
import { DashboardApprovalProfessionalComponent } from './pages/dashboard-approval/dashboard-approval-professional/dashboard-approval-professional.component';
import { DashboardApprovalDraftComponent } from './pages/dashboard-approval/dashboard-approval-draft/dashboard-approval-draft.component';
import { DashboardApprovalBondsComponent } from './pages/dashboard-approval/dashboard-approval-bonds/dashboard-approval-bonds.component';
import { DashboardProviderComponent } from './pages/dashboard-provider/dashboard-provider.component';
import { DashboardProvidersComponent } from './pages/dashboard-providers/dashboard-providers.component';

import { EditorModule } from '@tinymce/tinymce-angular';
import { CollapseComponent } from './components/collapse/collapse.component';
import { SearchComponent } from './components/search/search.component';
import { BadgeComponent } from './components/badge/badge.component';
import { AttachmentButtonComponent } from './components/attachment-button/attachment-button.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ServerErrorComponent } from './pages/server-error/server-error.component';
import { EmptyContentComponent } from './components/empty-content/empty-content.component';

import { RedirectUserComponent } from './pages/redirect-user/redirect-user.component';
import { AttachmentCollapseComponent } from './components/attachment-collapse/attachment-collapse.component';

import { SignupUnifiedBasicComponent } from './pages/signup-unified-basic/signup-unified-basic.component';
import { SignupUnifiedPersonalComponent } from './pages/signup-unified-basic/signup-unified-personal/signup-unified-personal.component';
import { SignupUnifiedPasswordComponent } from './pages/signup-unified-basic/signup-unified-password/signup-unified-password.component';
import { SignupUnifiedProfileComponent } from './pages/signup-unified-basic/signup-unified-profile/signup-unified-profile.component';
import { SignupUnifiedRequirementsComponent } from './pages/signup-unified-basic/signup-unified-requirements/signup-unified-requirements.component';
import { SignupUnifiedPersonComponent } from './pages/signup-unified-person/signup-unified-person.component';
import { SignupUnifiedCompanyComponent } from './pages/signup-unified-company/signup-unified-company.component';
import { SignupUnifiedCompanyAttachmentsComponent } from './pages/signup-unified-company/signup-unified-company-attachments/signup-unified-company-attachments.component';
import { SignupUnifiedCompanyComplementaryComponent } from './pages/signup-unified-company/signup-unified-company-complementary/signup-unified-company-complementary.component';
import { SignupUnifiedCompanyPowersComponent } from './pages/signup-unified-company/signup-unified-company-powers/signup-unified-company-powers.component';
import { SignupUnifiedCompanyComplementaryAddressComponent } from './pages/signup-unified-company/signup-unified-company-complementary/signup-unified-company-complementary-address/signup-unified-company-complementary-address.component';
import { SignupUnifiedCompanyComplementaryBankComponent } from './pages/signup-unified-company/signup-unified-company-complementary/signup-unified-company-complementary-bank/signup-unified-company-complementary-bank.component';
import { SignupUnifiedCompanyComplementaryCompositionComponent } from './pages/signup-unified-company/signup-unified-company-complementary/signup-unified-company-complementary-composition/signup-unified-company-complementary-composition.component';
import { SignupUnifiedCompanyComplementaryEconomicComponent } from './pages/signup-unified-company/signup-unified-company-complementary/signup-unified-company-complementary-economic/signup-unified-company-complementary-economic.component';
import { SignupUnifiedCompanyComplementaryGeneralComponent } from './pages/signup-unified-company/signup-unified-company-complementary/signup-unified-company-complementary-general/signup-unified-company-complementary-general.component';
import { SignupUnifiedCompanyComplementaryRepresentativeComponent } from './pages/signup-unified-company/signup-unified-company-complementary/signup-unified-company-complementary-representative/signup-unified-company-complementary-representative.component';
import { SignupUnifiedPersonComplementaryComponent } from './pages/signup-unified-person/signup-unified-person-complementary/signup-unified-person-complementary.component';
import { SignupUnifiedPersonAttachmentsComponent } from './pages/signup-unified-person/signup-unified-person-attachments/signup-unified-person-attachments.component';
import { SignupUnifiedPersonComplementaryAddressComponent } from './pages/signup-unified-person/signup-unified-person-complementary/signup-unified-person-complementary-address/signup-unified-person-complementary-address.component';
import { SignupUnifiedPersonComplementaryBankComponent } from './pages/signup-unified-person/signup-unified-person-complementary/signup-unified-person-complementary-bank/signup-unified-person-complementary-bank.component';
import { SignupUnifiedPersonComplementaryGeneralComponent } from './pages/signup-unified-person/signup-unified-person-complementary/signup-unified-person-complementary-general/signup-unified-person-complementary-general.component';
import { SignupUnifiedPersonComplementaryProfessionalComponent } from './pages/signup-unified-person/signup-unified-person-complementary/signup-unified-person-complementary-professional/signup-unified-person-complementary-professional.component';
import { SignupUnifiedPersonComplementaryRepresentativesComponent } from './pages/signup-unified-person/signup-unified-person-complementary/signup-unified-person-complementary-representatives/signup-unified-person-complementary-representatives.component';

// shareholder pf
import { SignupUnifiedPersonIncomeComponent } from './pages/signup-unified-person/signup-unified-person-income/signup-unified-person-income.component';
import { SignupUnifiedPersonIncomeOthersComponent } from './pages/signup-unified-person/signup-unified-person-income/signup-unified-person-income-others/signup-unified-person-income-others.component';
import { SignupUnifiedPersonIncomeRealtyComponent } from './pages/signup-unified-person/signup-unified-person-income/signup-unified-person-income-realty/signup-unified-person-income-realty.component';
import { SignupUnifiedPersonDeclarationsComponent } from './pages/signup-unified-person/signup-unified-person-declarations/signup-unified-person-declarations.component';
import { SignupUnifiedPersonAnalysisComponent } from './pages/signup-unified-person/signup-unified-person-analysis/signup-unified-person-analysis.component';
import { SignupUnifiedPersonConditionsComponent } from './pages/signup-unified-person/signup-unified-person-conditions/signup-unified-person-conditions.component';
import { SignupUnifiedPersonTaxComponent } from './pages/signup-unified-person/signup-unified-person-tax/signup-unified-person-tax.component';

// shareholder pj
import { SignupUnifiedCompanyIncomeComponent } from './pages/signup-unified-company/signup-unified-company-income/signup-unified-company-income.component';
import { SignupUnifiedCompanyIncomeOthersComponent } from './pages/signup-unified-company/signup-unified-company-income/signup-unified-company-income-others/signup-unified-company-income-others.component';
import { SignupUnifiedCompanyIncomeRealtyComponent } from './pages/signup-unified-company/signup-unified-company-income/signup-unified-company-income-realty/signup-unified-company-income-realty.component';
import { SignupUnifiedCompanyDeclarationsComponent } from './pages/signup-unified-company/signup-unified-company-declarations/signup-unified-company-declarations.component';
import { SignupUnifiedCompanyAnalysisComponent } from './pages/signup-unified-company/signup-unified-company-analysis/signup-unified-company-analysis.component';
import { SignupUnifiedCompanyConditionsComponent } from './pages/signup-unified-company/signup-unified-company-conditions/signup-unified-company-conditions.component';
import { SignupUnifiedCompanyTaxComponent } from './pages/signup-unified-company/signup-unified-company-tax/signup-unified-company-tax.component';

import { NgxClarityModule } from 'ngx-clarity';
import { ThemePipe } from './pipes/theme/theme.pipe';
import { DashboardFundApprovalRegisterComponent } from './pages/dashboard-fund-approval-register/dashboard-fund-approval-register.component';
import { DashboardFundApprovalRegisterBasicComponent } from './pages/dashboard-fund-approval-register/dashboard-fund-approval-register-basic/dashboard-fund-approval-register-basic.component';
import { DashboardFundApprovalRegisterComplementaryComponent } from './pages/dashboard-fund-approval-register/dashboard-fund-approval-register-complementary/dashboard-fund-approval-register-complementary.component';
import { DashboardFundApprovalRegisterTaxComponent } from './pages/dashboard-fund-approval-register/dashboard-fund-approval-register-tax/dashboard-fund-approval-register-tax.component';
import { DashboardFundApprovalRegisterProvidersComponent } from './pages/dashboard-fund-approval-register/dashboard-fund-approval-register-providers/dashboard-fund-approval-register-providers.component';
import { DashboardFundApprovalRegisterBankingComponent } from './pages/dashboard-fund-approval-register/dashboard-fund-approval-register-banking/dashboard-fund-approval-register-banking.component';
import { DashboardFundApprovalRegisterModelsComponent } from './pages/dashboard-fund-approval-register/dashboard-fund-approval-register-models/dashboard-fund-approval-register-models.component';
import UIDefaultTheme from 'src/themes';
import { InfoBadgeComponent } from './components/info-badge/info-badge.component';
import { CheckUserComponent } from './pages/check-user/check-user.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { BasicModalComponent } from './components/basic-modal/basic-modal.component';
import { ModalModule } from 'ng-modal-lib';
import { UserSettingsModalComponent } from './components/user-settings-modal/user-settings-modal.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { ApprovationStepsComponent } from './components/approvation-steps/approvation-steps.component';
import { DashboardFundApprovalFilesComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-files/dashboard-fund-approval-files.component';
import { DashboardFundApprovalAccompanimentComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-accompaniment/dashboard-fund-approval-accompaniment.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ErrorHandlerPipe } from './pipes/error-handler/error-handler.pipe';
import { SafeDataComponent } from './components/safe-data/safe-data.component';
import { DashboardAccountsComponent } from './pages/dashboard-accounts/dashboard-accounts.component';
import { DashboardRequestAcessComponent } from './pages/dashboard-request-acess/dashboard-request-acess.component';
import { DashboardContributorsDepartmentsComponent } from './pages/dashboard-contributors/dashboard-contributors-departments/dashboard-contributors-departments.component';
import { DashboardContributorsCollaboratorsComponent } from './pages/dashboard-contributors/dashboard-contributors-collaborators/dashboard-contributors-collaborators.component';
import { DashboardContributorsWitnessesComponent } from './pages/dashboard-contributors/dashboard-contributors-witnesses/dashboard-contributors-witnesses.component';
import { DashboardWitnessesComponent } from './pages/dashboard-witnesses/dashboard-witnesses.component';
import { DashboardRepresentativeApprovalComponent } from './pages/dashboard-representative-approval/dashboard-representative-approval.component';
import { DashboardRepresentativeApprovalBasicComponent } from './pages/dashboard-representative-approval/dashboard-representative-approval-basic/dashboard-representative-approval-basic.component';
import { DashboardRepresentativeApprovalBondsComponent } from './pages/dashboard-representative-approval/dashboard-representative-approval-bonds/dashboard-representative-approval-bonds.component';
import { DashboardDraftsComponent } from './pages/dashboard-drafts/dashboard-drafts.component';
import { DashboardDraftsFormComponent } from './pages/dashboard-drafts-form/dashboard-drafts-form.component';
import { DashboardFundApprovalRegisterQuotasComponent } from './pages/dashboard-fund-approval-register/dashboard-fund-approval-register-quotas/dashboard-fund-approval-register-quotas.component';
import { VerifyPermissionComponent } from './components/verify-permission/verify-permission.component';
import { DashboardFundApprovalQuotasComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval-quotas/dashboard-fund-approval-quotas.component';
import { TableRowComponent } from './components/table-row/table-row.component';
import { DashboardInvitesComponent } from './pages/dashboard-invites/dashboard-invites.component';
import { NewTableComponent } from './components/new-table/new-table.component';
import { SignupUnifiedCompanyComplementaryBondComponent } from './pages/signup-unified-company/signup-unified-company-complementary/signup-unified-company-complementary-bond/signup-unified-company-complementary-bond.component';
import { SignupUnifiedCompanyComplementaryAdminComponent } from './pages/signup-unified-company/signup-unified-company-complementary/signup-unified-company-complementary-admin/signup-unified-company-complementary-admin.component';
import { SignupUnifiedCompanyComplementaryManagerComponent } from './pages/signup-unified-company/signup-unified-company-complementary/signup-unified-company-complementary-manager/signup-unified-company-complementary-manager.component';
import { DashboardApprovalIncomeComponent } from './pages/dashboard-approval/dashboard-approval-income/dashboard-approval-income.component';
import { DashboardApprovalProvidersComponent } from './pages/dashboard-approval/dashboard-approval-providers/dashboard-approval-providers.component';
import { SkeletonLoginComponent } from './skeleton/skeleton-login/skeleton-login.component';
import { SignupUnifiedPersonPowersComponent } from './pages/signup-unified-person/signup-unified-person-powers/signup-unified-person-powers.component';
import { DashboardSettingsPermissionsComponent } from './pages/dashboard-settings/dashboard-settings-permissions/dashboard-settings-permissions.component';
import { DashboardPermissionsDetailsComponent } from './pages/dashboard-permissions-group/dashboard-permissions/dashboard-permissions-details/dashboard-permissions-details.component';
import { DashboardPermissionsRegisterComponent } from './pages/dashboard-permissions-group/dashboard-permissions/dashboard-permissions-register/dashboard-permissions-register.component';
import { DashboardPermissionsGroupComponent } from './pages/dashboard-permissions-group/dashboard-permissions-group.component';
import { DashboardPermissionsComponent } from './pages/dashboard-permissions-group/dashboard-permissions/dashboard-permissions.component';
import { DashboardUsersGroupComponent } from './pages/dashboard-permissions-group/dashboard-users-group/dashboard-users-group.component';
import { DashboardCompaniesComponent } from './pages/dashboard-permissions-group/dashboard-companies/dashboard-companies.component';
import { DashboardHistoryComponent } from './pages/dashboard-permissions-group/dashboard-history/dashboard-history.component';
import { DashboardCompanyComponent } from './pages/dashboard-permissions-group/dashboard-companies/dashboard-company/dashboard-company.component';
import { DashboardCompanyDetailsComponent } from './pages/dashboard-permissions-group/dashboard-companies/dashboard-company/dashboard-company-details/dashboard-company-details.component';
import { DashboardCompanyBondUsersComponent } from './pages/dashboard-permissions-group/dashboard-companies/dashboard-company/dashboard-company-bond-users/dashboard-company-bond-users.component';
import { DashboardCompanyBondFundsComponent } from './pages/dashboard-permissions-group/dashboard-companies/dashboard-company/dashboard-company-bond-funds/dashboard-company-bond-funds.component';
import { DashboardUsersGroupDetailsComponent } from './pages/dashboard-permissions-group/dashboard-users-group/dashboard-users-group-details/dashboard-users-group-details.component';
import { DashboardHistoryDetailsComponent } from './pages/dashboard-permissions-group/dashboard-history/dashboard-history-details/dashboard-history-details.component';
import { DashboardSettingsGroupUsersComponent } from './pages/dashboard-settings/dashboard-settings-group-users/dashboard-settings-group-users.component';
import { DashboardGroupUsersComponent } from './pages/dashboard-group-users/dashboard-group-users.component';
import { DashboardUsersComponent } from './pages/dashboard-group-users/dashboard-users/dashboard-users.component';
import { DashboardGroupsComponent } from './pages/dashboard-group-users/dashboard-groups/dashboard-groups.component';
import { DashboardUserDetailsComponent } from './pages/dashboard-group-users/dashboard-users/dashboard-user-details/dashboard-user-details.component';
import { DashboardUserHistoryDetailsComponent } from './pages/dashboard-group-users/dashboard-users/dashboard-user-details/dashboard-user-history-details/dashboard-user-history-details.component';
import { DashboardGroupRegisterComponent } from './pages/dashboard-group-users/dashboard-groups/dashboard-group-register/dashboard-group-register.component';
import { DashboardCompanyPermissionsGroupsComponent } from './pages/dashboard-permissions-group/dashboard-companies/dashboard-company/dashboard-company-permissions-groups/dashboard-company-permissions-groups.component';
import { SignupUnifiedPersonComplementaryRiskComponent } from './pages/signup-unified-person/signup-unified-person-complementary/signup-unified-person-complementary-risk/signup-unified-person-complementary-risk.component';
import { SignupUnifiedCompanyComplementaryRiskComponent } from './pages/signup-unified-company/signup-unified-company-complementary/signup-unified-company-complementary-risk/signup-unified-company-complementary-risk.component';
import { DashboardApprovalRiskComponent } from './pages/dashboard-approval/dashboard-approval-risk/dashboard-approval-risk.component';
import { DashboardApprovalDeclarationsComponent } from './pages/dashboard-approval/dashboard-approval-declarations/dashboard-approval-declarations.component';

registerLocaleData(localePt, 'pt');

const componentParsers: Array<HookParserEntry> = [
  { component: SvgIconComponent, selector: 'svg-icon' },
];

const isLocal = window.location.href.includes('localhost');

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgbModule,
    NgSelectModule,
    NgxDropzoneModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    AngularSvgIconModule.forRoot(),
    SweetAlert2Module.forRoot(),
    EditorModule,
    UiSwitchModule.forRoot({
      size: 'small',
      color: 'rgb(0, 98, 179)',
    }),
    NgxGoogleAnalyticsModule.forRoot('G-W92JBV1YJ1'),
    NgxGoogleAnalyticsRouterModule,
    CookieModule.forRoot(),
    DynamicHooksModule.forRoot({
      globalParsers: componentParsers,
    }),
    NgxClarityModule.forRoot({
      enabled: !isLocal,
      projectId: UIDefaultTheme.api.clarity_id,
    }),
    ModalModule,
    NgxSkeletonLoaderModule,
  ],

  declarations: [
    AppComponent,
    LoginComponent,
    InputComponent,
    ButtonComponent,
    SpinnerComponent,
    TabsComponent,
    RadioComponent,
    SelectComponent,
    DropzoneComponent,
    TableComponent,
    ToastComponent,
    ComponentsComponent,
    SpacerComponent,
    ValidateComponent,
    ChoicesComponent,
    ForgotComponent,
    ContainerComponent,
    StepperComponent,
    ProgressbarComponent,

    SignupStatusComponent,

    AttributeDirective,
    CurrencyInputComponent,
    MaskedInputComponent,
    DashSidebarComponent,
    DashboardComponent,
    DashboardShareholderComponent,
    DashboardShareholderDeclarationsComponent,
    DashboardShareholderConditionsComponent,
    DashboardShareholderTaxComponent,
    DashboardAccountBasicComponent,

    DashboardSecurityComponent,
    DashboardOrganizationComponent,
    SideModalComponent,
    DashboardSettingsComponent,
    DashboardAgentsComponent,
    DashboardContributorsComponent,
    DashboardDocumentsComponent,
    LoadingComponent,
    FormErrorIndicatorComponent,

    DashboardPersonComponent,
    DashboardPersonAnalysisComponent,
    DashboardPersonAttachmentsComponent,
    DashboardPersonComplementaryComponent,
    DashboardPersonConditionsComponent,
    DashboardPersonDeclarationsComponent,

    DashboardPersonComplementaryAddressComponent,
    DashboardPersonComplementaryBankComponent,
    DashboardPersonComplementaryGeneralComponent,
    DashboardPersonComplementaryProfessionalComponent,
    DashboardPersonComplementaryRepresentativesComponent,

    DashboardPersonIncomeComponent,
    DashboardPersonIncomeOthersComponent,
    DashboardPersonIncomeRealtyComponent,
    DashboardPersonIncomeSalaryComponent,

    DashboardPersonTaxComponent,
    DashboardPersonTermsComponent,
    DashboardCompanyAnalysisComponent,
    DashboardCompanyAttachmentsComponent,
    DashboardCompanyComplementaryComponent,
    DashboardCompanyConditionsComponent,
    DashboardCompanyDeclarationsComponent,
    DashboardCompanyIncomeComponent,
    DashboardCompanyTaxComponent,
    DashboardCompanyTermsComponent,
    DashboardCompanyComplemenntaryAddressComponent,
    DashboardCompanyComplemenntaryBankComponent,
    DashboardCompanyComplemenntaryCompositionComponent,
    DashboardCompanyComplemenntaryEconomicComponent,
    DashboardCompanyComplemenntaryGeneralComponent,
    DashboardCompanyComplemenntaryRepresentativesComponent,
    DashboardCompanyIncomeOthersComponent,
    DashboardCompanyIncomeRealtyComponent,
    DashboardCompanyIncomeSalaryComponent,

    UpperCaseDirective,
    DashboardAdminComponent,
    LabelComponent,
    DashboardCollaboratorsComponent,
    DashboardDepartmentsComponent,
    DashboardApprovalComponent,
    SwitchComponent,
    DashboardAssignorComponent,
    WarningModalComponent,
    PaginationComponent,
    ResetPasswordComponent,
    DashboardFundsComponent,
    DashboardShareholdersComponent,
    DashboardAssignorsComponent,
    BackButtonComponent,
    SignatureCardComponent,
    DashboardApprovalComplementaryComponent,
    TableListComponent,
    DashboardApprovalCompositionComponent,
    DashboardApprovalRepresentativesComponent,
    DashboardApprovalAttachmentsComponent,
    DashboardApprovalSignaturesComponent,
    DashboardApprovalAccompanimentComponent,
    DashboardApprovalDocumentsComponent,
    DocumentVisualizerModalComponent,
    ButtonWithDropdownComponent,
    DashboardFundApprovalComponent,
    DashboardFundApprovalComplementaryComponent,
    DashboardFundApprovalBasicComponent,
    DashboardFundApprovalTransfersComponent,
    DashboardFundApprovalProvidersComponent,
    DashboardFundApprovalTaxComponent,
    DashboardFundApprovalBankingComponent,
    DashboardFundApprovalAttachmentsComponent,
    DashboardFundApprovalDocumentsComponent,
    DashboardFundApprovalShareholderComponent,
    DashboardFundApprovalAssignorComponent,
    DashboardSettingsAgentsComponent,
    DashboardSettingsDocumentsComponent,
    DashboardSettingsZeroingFundsComponent,
    DashboardZeroingFundsComponent,
    DashboardSettingsAuditComponent,
    DashboardAuditComponent,
    DashboardAdminAssignorsComponent,
    DashboardDocumentModelComponent,
    DashboardUserListComponent,
    DashboardApprovalPowersComponent,
    CollapseComponent,
    DashboardProvidersComponent,
    SearchComponent,
    EmptyContentComponent,
    BadgeComponent,
    AttachmentButtonComponent,
    NotFoundComponent,
    ServerErrorComponent,
    DashboardApprovalProfessionalComponent,
    DashboardApprovalBondsComponent,
    DashboardProviderComponent,
    RedirectUserComponent,
    AttachmentCollapseComponent,
    SignupUnifiedBasicComponent,
    SignupUnifiedPersonalComponent,
    SignupUnifiedPasswordComponent,
    SignupUnifiedProfileComponent,
    SignupUnifiedRequirementsComponent,
    SignupUnifiedPersonComponent,
    SignupUnifiedCompanyComponent,
    SignupUnifiedCompanyAttachmentsComponent,
    SignupUnifiedCompanyComplementaryComponent,
    SignupUnifiedCompanyPowersComponent,
    SignupUnifiedCompanyComplementaryAddressComponent,
    SignupUnifiedCompanyComplementaryBankComponent,
    SignupUnifiedCompanyComplementaryCompositionComponent,
    SignupUnifiedCompanyComplementaryEconomicComponent,
    SignupUnifiedCompanyComplementaryGeneralComponent,
    SignupUnifiedCompanyComplementaryRepresentativeComponent,
    SignupUnifiedPersonComplementaryComponent,
    SignupUnifiedPersonAttachmentsComponent,
    SignupUnifiedPersonComplementaryAddressComponent,
    SignupUnifiedPersonComplementaryBankComponent,
    SignupUnifiedPersonComplementaryGeneralComponent,
    SignupUnifiedPersonComplementaryProfessionalComponent,
    SignupUnifiedPersonComplementaryRepresentativesComponent,
    DashboardApprovalDraftComponent,
    ThemePipe,
    DashboardFundApprovalRegisterComponent,
    DashboardFundApprovalRegisterBasicComponent,
    DashboardFundApprovalRegisterComplementaryComponent,
    DashboardFundApprovalRegisterTaxComponent,
    DashboardFundApprovalRegisterProvidersComponent,
    DashboardFundApprovalRegisterBankingComponent,
    DashboardFundApprovalRegisterModelsComponent,
    InfoBadgeComponent,
    CheckUserComponent,
    DateInputComponent,
    BasicModalComponent,
    UserSettingsModalComponent,
    MaintenanceComponent,
    ApprovationStepsComponent,
    DashboardFundApprovalFilesComponent,
    DashboardFundApprovalAccompanimentComponent,
    ErrorHandlerPipe,
    SafeDataComponent,
    DashboardAccountsComponent,
    DashboardRequestAcessComponent,
    DashboardContributorsDepartmentsComponent,
    DashboardContributorsCollaboratorsComponent,
    DashboardContributorsWitnessesComponent,
    DashboardWitnessesComponent,
    DashboardRepresentativeApprovalComponent,
    DashboardRepresentativeApprovalBasicComponent,
    DashboardRepresentativeApprovalBondsComponent,
    DashboardDraftsComponent,
    DashboardDraftsFormComponent,
    DashboardFundApprovalRegisterQuotasComponent,
    VerifyPermissionComponent,
    DashboardFundApprovalQuotasComponent,
    TableRowComponent,
    DashboardInvitesComponent,
    NewTableComponent,
    SignupUnifiedPersonIncomeComponent,
    SignupUnifiedPersonIncomeOthersComponent,
    SignupUnifiedPersonIncomeRealtyComponent,
    SignupUnifiedPersonDeclarationsComponent,
    SignupUnifiedPersonAnalysisComponent,
    SignupUnifiedPersonConditionsComponent,
    SignupUnifiedPersonTaxComponent,
    SignupUnifiedCompanyIncomeComponent,
    SignupUnifiedCompanyIncomeOthersComponent,
    SignupUnifiedCompanyIncomeRealtyComponent,
    SignupUnifiedCompanyDeclarationsComponent,
    SignupUnifiedCompanyAnalysisComponent,
    SignupUnifiedCompanyConditionsComponent,
    SignupUnifiedCompanyTaxComponent,
    SignupUnifiedCompanyComplementaryBondComponent,
    SignupUnifiedCompanyComplementaryAdminComponent,
    SignupUnifiedCompanyComplementaryManagerComponent,
    DashboardApprovalIncomeComponent,
    DashboardApprovalProvidersComponent,
    SkeletonLoginComponent,
    SignupUnifiedPersonPowersComponent,
    DashboardSettingsPermissionsComponent,
    DashboardPermissionsDetailsComponent,
    DashboardPermissionsRegisterComponent,
    DashboardPermissionsGroupComponent,
    DashboardPermissionsComponent,
    DashboardUsersGroupComponent,
    DashboardCompaniesComponent,
    DashboardHistoryComponent,
    DashboardCompanyComponent,
    DashboardCompanyDetailsComponent,
    DashboardCompanyBondUsersComponent,
    DashboardCompanyBondFundsComponent,
    DashboardUsersGroupDetailsComponent,
    DashboardHistoryDetailsComponent,
    DashboardSettingsGroupUsersComponent,
    DashboardGroupUsersComponent,
    DashboardUsersComponent,
    DashboardGroupsComponent,
    DashboardUserDetailsComponent,
    DashboardUserHistoryDetailsComponent,
    DashboardGroupRegisterComponent,
    DashboardCompanyPermissionsGroupsComponent,
    SignupUnifiedPersonComplementaryRiskComponent,
    SignupUnifiedCompanyComplementaryRiskComponent,
    DashboardApprovalRiskComponent,
    DashboardApprovalDeclarationsComponent,
  ],
  providers: [
    FormBuilder,
    MaskPipe,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
