import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import UIDefaultTheme from 'src/themes';

@Component({
  selector: 'app-signup-status',
  templateUrl: './signup-status.component.html',
  styleUrls: ['./signup-status.component.scss'],
})
export class SignupStatusComponent implements OnInit {
  steps: Step[] = [
    {
      icon: 'fas fa-check',
      svg: '',
      title: 'Cadastro concluído',
      slug: 'completed',
    },
    {
      icon: 'fas fa-thumbs-up',
      svg: 'assets/images/icon-signature.svg',
      title: 'Assinatura pendente',
      slug: 'signature',
    },
    {
      icon: 'far fa-clock',
      svg: 'assets/images/icon-clock.svg',
      title: 'Em análise',
      slug: 'approvalAnalysis',
    },
  ];
  activeStep = 1;

  userRole = '';
  text = '';
  title = '';

  isRepresentative = false;

  alreadySigned = false;

  company_data: Company = undefined;
  company_register: Register = undefined;
  company_register_id: string = undefined;

  representative_id = -1;

  isOwner = false;
  accountsLoaded = false;

  hasMultipleAccounts = false;
  accounts: RegisterUserResponse[] = [];

  public UIDefaultTheme = UIDefaultTheme;

  constructor(
    private router: Router,
    public authService: AuthService,
    private route: ActivatedRoute,
    private api: ApiService,
    private toast: ToastService,
    private signupService: SignupService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.signupService.fetchDataEvent.emit();

    this.userRole = this.router.url.split('?')[0].slice(-2).toUpperCase();
    this.company_register_id = this.route.snapshot.queryParams.company;

    this.route.queryParams.subscribe(async ({ company, token }) => {
      if (company) {
        this.getData(company);
      } else {
        this.setUserRoleInformations();
      }
    });
  }

  async getData(id: string) {
    try {
      const { data } = await this.api.get<ApiResponse<Company>>({
        route: `api/registration/pj_general/${id}/`,
        token: true,
      });

      this.company_data = data;
      this.getRegister(data.register_id);
      this.setUserRoleInformations();
      this.getAccounts();

      this.isOwner =
        this.authService.user.active_register?.register.id === data.register_id;
    } catch (error) {
      console.error(error);
    }
  }

  canSign(sign: SignProps) {
    this.isRepresentative =
      this.authService.user.active_register?.register.id === sign.register.id;

    return !sign.signed && this.isRepresentative;
  }

  canResend(sign: SignProps) {
    if (sign.signed) {
      return false;
    }

    if (this.isOwner && this.company_data && this.company_data.id) {
      return true;
    }

    return false;
  }

  async getRegister(id) {
    try {
      if (!id) {
        return;
      }
      const { data } = await this.api.get<ApiResponse<Register>>({
        route: `api/registration/register/${id}/`,
        token: true,
      });

      this.company_register = data;
    } catch (error) {
      console.error(error);
    }
  }

  setUserRoleInformations() {
    if (this.userRole === 'PJ') {
      const name = this.company_data ? this.company_data.corporate_name : '';
      this.title = `O cadastro da empresa ${name} está quase pronto...`;
      this.text =
        'Por questões de segurança é necessário que um ou mais representantes realizem a assinatura digital para avançar para a etapa de análise. Um e-mail com o link de assinatura já foi enviado para os respectivos.';
    }

    if (this.userRole === 'PF') {
      this.title = 'Pronto, agora é só aguardar...';
      this.text =
        'Seu cadastro foi concluído e está sob análise. <br>Você será notificado por e-mail quando seu cadastro for aprovado.';
    }

    if (this.isRepresentative) {
      this.title = 'Confirmação de segurança';
      this.text =
        'É necessário que um ou mais representantes realizem a assinatura digital para avançar para a etapa de análise';
    }
  }

  async goToPanel() {
    if (this.hasMultipleAccounts) {
      const storageActiveRegister = this.storageService.getEditUser();
      const findedActiveRegister = this.accounts.find(
        (account) => account?.register?.id === storageActiveRegister
      );

      let active_register = findedActiveRegister
        ? findedActiveRegister.id
        : this.accounts[0].id;

      const res = await this.api.patch({
        route: `user/me/`,
        token: true,
        body: {
          active_register: active_register,
        },
      });

      const { data } = await this.api.get<ApiResponse<NewLoginResponse>>({
        route: 'user/me/',
        token: true,
      });

      this.storageService.setPermissionType(data.type_permission);

      this.authService.setUser(data);
      this.storageService.remove('edit_user');

      this.router.navigateByUrl('/app/registers');
    } else {
      this.router.navigate(['/app/dashboard']);
    }
  }

  async getAccounts() {
    try {
      const data = await this.api.get<RegisterUserResponse[]>({
        route: 'api/v2/registration/account-switch/',
        token: true,
        params: {
          type: 'profile',
          mode: 'list',
        },
      });

      const newData = data.sort((a, b) => b.id - a.id).reverse();
      this.accounts = newData;

      this.hasMultipleAccounts = data.length > 1;
    } catch (error) {
      console.error(error);
    }

    this.accountsLoaded = true;
  }

  async resendMail(id: number) {
    try {
      const res = await this.api.post({
        route: 'api/approvals/company/resend',
        token: true,
        body: {
          representative_id: id,
        },
      });

      this.toast.show('info', 'Sucesso!', 'E-mail enviado com sucesso');
    } catch (error) {
      this.toast.show('error', 'Erro!', 'Erro ao enviar e-mail');
    }
  }

  redirectToDetails(id) {
    if (this.company_register) {
      this.router.navigateByUrl(
        `/app/details/${this.company_register.role.applicable}/${this.company_register.id}?sign_view=${id}`
      );
    }
  }
}
