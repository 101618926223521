<div class="row no-gutters">
    <div class="col-12 offset-lg-1 col-lg-11">

        <div class="row">
            <div class="col-12">
                <h2 class="title">{{title}}</h2>
            </div>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <app-signup-unified-company-complementary-general [userRole]="role"
            *ngIf="stepSlug === 'complementaryGeneral'">
        </app-signup-unified-company-complementary-general>

        <app-signup-unified-company-complementary-address
            *ngIf="stepSlug === 'complementaryAddress'">
        </app-signup-unified-company-complementary-address>

        <app-signup-unified-company-complementary-bank [userRole]="role"
            *ngIf="stepSlug === 'complementaryBank'">
        </app-signup-unified-company-complementary-bank>

        <app-signup-unified-company-complementary-composition [userRole]="role"
            *ngIf="stepSlug === 'complementaryComposition'">
        </app-signup-unified-company-complementary-composition>

        <app-signup-unified-company-complementary-economic
            *ngIf="stepSlug === 'complementaryEconomic'">
        </app-signup-unified-company-complementary-economic>

        <app-signup-unified-company-complementary-bond
            *ngIf="stepSlug === 'complementaryBond'">
        </app-signup-unified-company-complementary-bond>

        <app-signup-unified-company-complementary-risk
            *ngIf="stepSlug === 'complementaryRisk'">
        </app-signup-unified-company-complementary-risk>

        <app-signup-unified-company-complementary-representative
            *ngIf="stepSlug === 'complementaryRepresentatives'">
        </app-signup-unified-company-complementary-representative>

        <app-signup-unified-company-complementary-admin
            *ngIf="stepSlug === 'complementaryAdmin'">
        </app-signup-unified-company-complementary-admin>

        <app-signup-unified-company-complementary-manager
            *ngIf="stepSlug === 'complementaryManager'">
        </app-signup-unified-company-complementary-manager>
    </div>
</div>