import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaskPipe } from 'ngx-mask';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-fund-approval-assignor',
  templateUrl: './dashboard-fund-approval-assignor.component.html',
  styleUrls: ['./dashboard-fund-approval-assignor.component.scss'],
})
export class DashboardFundApprovalAssignorComponent implements OnInit {
  @Output() onClick = new EventEmitter<MouseEvent>();

  loading = true;
  data;

  status_dict = {
    created: 'Criado',
    INITIATED: 'Iniciado',
    AVAIABLE: 'Disponível',
    REQUESTED: 'Solicitado',
    APPROVED: 'Aprovado',
    REJECTED: 'Rejeitado',
  };

  columns: TableColumnList<any>[] = [
    {
      name: 'Cedente',
      cell: (row) => `
    <div class="dash-card-item p-0">
        <div class="dash-card-item-initials mr-3">
            <span>${this.getInitials(row.links.register)}</span>
        </div>

        <div class="dash-card-item-content">
            <div class="dash-card-item-content-title">
                ${
                  row.links.register.type === 'PF'
                    ? row.links.register.person.full_name
                    : row.links.register.company.corporate_name
                }
            </div>
            <div class="dash-card-item-content-subtitle">
                ${
                  row.links.register
                    ? this.formatDocument(row.links.register)
                    : ''
                }
            </div>
        </div>
    </div>
        `,
      size: 2,
    },
    {
      name: 'Status',
      cell: (row) => this.status_dict[row.links.register.status],
    },
    {
      name: 'Última atualização',
      cell: (row) => this.formatDate(row.links.register.updated_at),
    },
    {
      name: '',
      cell: (row) =>
        `<div class="d-flex align-items-center icon-container">
      <div class="assignor-visualizer row-${row.links.register.id}">
          <i class="fas fa-eye"></i>
      </div>
              </div>`,
    },
  ];
  //   data: Partial<ShareholderListValues>[] = [];

  constructor(
    public fundService: FundService,
    private maskPipe: MaskPipe,
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    if (this.fundService.fundData.assignors) {
      this.data = this.fundService.fundData.assignors;
      this.loading = false;
    } else {
      this.getFundData();
    }
  }

  //   ngAfterViewInit() {
  //     this.setHandlers();
  //   }

  //   ngOnChanges(changes: SimpleChanges) {
  //     setTimeout(() => {
  //       this.setHandlers();
  //     }, 1000);
  //   }

  setHandlers() {
    const visualizer = document.querySelectorAll('.assignor-visualizer');

    visualizer.forEach((button) => {
      button.addEventListener('click', (event) => {
        const el: any = event.currentTarget;
        const idClass: any = el.classList.item(1);
        const id = idClass.split('-').slice(1).join('-');

        const finded = this.data.find((item: any) => {
          if (item.links.register.id) {
            return item.links.register.id === id;
          }

          return false;
        });

        if (finded) {
          this.handleRedirect(finded);
        }
      });
    });
  }

  handleRedirect(assignor: any) {
    this.router.navigateByUrl(
      '/app/details/assignor/' + assignor.links.register.id
    );
  }

  formatDate(date: string) {
    return date.split('T')[0].split('-').reverse().join('/');
  }

  formatDocument(user: any) {
    if (user.type === 'PF') {
      return this.maskPipe.transform(
        user.person.document.number,
        '000.000.000-00'
      );
    } else {
      return this.maskPipe.transform(
        user.company.document.number,
        '00.000.000/0000-00'
      );
    }
  }

  getInitials(user: any) {
    if (user.type === 'PF') {
      const full_name = user.person.full_name;

      return Utils.getInitials(full_name);
    } else {
      const corporate_name = user.company.corporate_name;

      return Utils.getInitials(corporate_name);
    }
  }

  async getFundData() {
    try {
      const data = await this.api.get({
        route: 'api/v2/approvals/version/',
        params: {
          fund: this.route.snapshot.params.id,
          tab: 'assignors',
        },
        token: true,
      });

      const fund = { ...data };

      if (fund[0].fund && fund[0].fund.length === 0) {
        this.loading = false;
        return;
      }

      this.data = fund[0].fund[0].links;

      this.fundService.fundData = {
        ...this.fundService.fundData,
        assignors: fund[0].fund[0].links,
      };

      setTimeout(() => {
        this.setHandlers();
      }, 500);
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao carregar cedentes: ' + error);
    }

    this.loading = false;
  }
}
