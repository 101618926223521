<div class="row">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <ng-container *ngIf="loading">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-3 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loading">
                <div class="dash-details-card-body">
                    <ng-container *ngIf="data">
                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="Zeragem automática">
                                </app-label>
                            </div>
                        </div>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNPJ DO FUNDO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{zeroingFund && zeroingFund.zeroing_fund ?
                                    zeroingFund.zeroing_fund.document : '--'}}
                                </span>
                            </div>

                            <div class="col-6 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME DO FUNDO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{zeroingFund && zeroingFund.zeroing_fund ?
                                    zeroingFund.zeroing_fund.corporate_name :
                                    '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME CONTATO GESTOR
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{zeroingFund && zeroingFund.zeroing_fund ?
                                    zeroingFund.zeroing_fund.manager_name :
                                    '--'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    TELEFONE GESTOR
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{zeroingFund && zeroingFund.zeroing_fund ?
                                    zeroingFund.zeroing_fund.manager_phone :
                                    '--'}}
                                </span>
                            </div>

                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    E-MAIL GESTOR
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{zeroingFund && zeroingFund.zeroing_fund ?
                                    zeroingFund.zeroing_fund.manager_email :
                                    '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <!-- <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            0*
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            TIPO LIQUIDAÇÃO
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer> -->

                        <div class="row">
                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Fundo de zeragem
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{zeroingFund && zeroingFund.zeroing_fund ?
                                    zeroingFund.zeroing_fund.corporate_name :
                                    '--' }}
                                </span>
                            </div>

                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    BANCO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{zeroingFund && zeroingFund.bank ?
                                    zeroingFund.bank.name : '--' }}
                                </span>
                            </div>

                            <div class="col-1 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    AGÊNCIA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{zeroingFund ? zeroingFund.agency : '--'}}
                                </span>
                            </div>

                            <div class="col-1 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CONTA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{zeroingFund ? zeroingFund.account : '--'}}
                                </span>
                            </div>
                        </div>

                        <!-- <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            0*
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            CNPJ DO FAVORECIDO
                        </span>
                    </div>

                    <div class="col-4 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            0*
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            FAVORECIDO
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="16"></app-spacer> -->

                        <!-- <div class="row">
                    <div class="col-2 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            0*
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            CETIP
                        </span>
                    </div>
                </div> -->
                        <app-spacer [bottom]="26"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="Zeragem em compromissada">
                                </app-label>
                            </div>
                        </div>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME CONTATO GESTOR
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.manager_name ?? '--'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    TELEFONE GESTOR
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.manager_phone ?? '--'}}
                                </span>
                            </div>

                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    E-MAIL GESTOR
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.manager_email ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="26"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="Conta corrente">
                                </app-label>
                            </div>
                        </div>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="row">
                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    BANCO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.checking_account_bank
                                    ?
                                    data.client_service?.checking_account_bank.name
                                    :'--'}}
                                </span>
                            </div>

                            <div class="col-1 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    AGÊNCIA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.checking_account_agency
                                    ?? '--'}}
                                </span>
                            </div>

                            <div class="col-1 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CONTA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.checking_account_number
                                    ??
                                    '--'}}
                                    {{data.client_service?.checking_account_bank_number
                                    ? '--' :''}}
                                    {{data.client_service?.checking_account_bank_number
                                    ?? ''}}
                                    <!-- mudar isso dps -->
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="26"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="Conta cobrança">
                                </app-label>
                            </div>
                        </div>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="row">
                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    BANCO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.charge_account_bank ?
                                    data.client_service?.charge_account_bank.name
                                    :'--'}}
                                </span>
                            </div>

                            <div class="col-1 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    AGÊNCIA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.charge_account_agency
                                    ?? '--'}}
                                </span>
                            </div>

                            <div class="col-1 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CONTA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.charge_account_number
                                    ?? '--'}}
                                    {{data.client_service?.charge_account_bank_number
                                    ? '--' :''}}
                                    {{data.client_service?.charge_account_bank_number
                                    ?? ''}}
                                </span>
                            </div>

                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NÚMERO CONTRATO COBRANÇA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.charge_account_contract_number
                                    ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="26"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="Conta escrow">
                                </app-label>
                            </div>
                        </div>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="row">
                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    BANCO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.escrow_account_bank ?
                                    data.client_service?.escrow_account_bank.name
                                    :'--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="26"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="CLBC">
                                </app-label>
                            </div>
                        </div>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NÚMERO DO CONTRATO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.cblc_contract_number
                                    ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="26"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="CETIP">
                                </app-label>
                            </div>
                        </div>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NÚMERO DO CONTRATO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.cetip_contract_number
                                    ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="26"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="SELIC">
                                </app-label>
                            </div>
                        </div>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NÚMERO DO CONTRATO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.selic_contract_number
                                    ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="26"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="CONTA GARANTIA - CBLC">
                                </app-label>
                            </div>
                        </div>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NÚMERO DO CONTRATO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.guaranteed_cblc_contract_number
                                    ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="26"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="CONTA GARANTIA - BM&F">
                                </app-label>
                            </div>
                        </div>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NÚMERO DO CONTRATO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.client_service?.guaranteed_bmf_contract_number
                                    ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="26"></app-spacer>

                        <!-- <div class="row">
                    <div class="col-12">
                        <app-label class="font-weight-bold"
                            label="Conta Interna">
                        </app-label>
                    </div>
                </div>
                <app-spacer [bottom]="8"></app-spacer>

                <div class="row">
                    <div class="col-1 d-flex flex-column">
                        <span class="dash-details-card-item-title">
                            {{data.client_service?.internal_account_number
                            ?? '--'}}
                        </span>

                        <span class="dash-details-card-item-subtitle">
                            CONTA
                        </span>
                    </div>
                </div>
                <app-spacer [bottom]="26"></app-spacer> -->
                    </ng-container>

                    <app-empty-content [isVisible]="!data"
                        title="Nenhum dado encontrado"
                        description="Não foi possível encontrar os dados bancários do fundo."
                        icon="/assets/images/forgot-password-icon2.png">
                    </app-empty-content>
                </div>

            </ng-container>
        </div>
    </div>
</div>