<div class="row no-gutters" [formGroup]="form">
    <div class="col-12 offset-lg-1 col-lg-10">

        <ng-container *ngFor="let option of options; let i = index">
            <div class="row">
                <div class="col-12 col-lg-10">
                    <span
                        *ngIf="form.controls[option.formControl].value === null"
                        class="is-required">*</span>
                    <span class="basic-content-subtitle">{{option.title}}</span>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>

            <div class="row"
                *ngFor="let suboption of option.suboptions;let i = index">

                <div class="offset-1 col-9">
                    <app-radio
                        [isChecked]="form.controls[option.formControl].value === suboption.value"
                        [formControlName]="option.formControl"
                        [value]="suboption.value">
                        <div class="flex-1 d-flex flex-column">
                            <span
                                class="basic-content-description"><strong>{{i+1}})</strong>
                                {{suboption.label}}</span>
                        </div>
                    </app-radio>

                    <app-spacer [bottom]="24"></app-spacer>
                </div>

            </div>

            <app-spacer [bottom]="24"></app-spacer>
        </ng-container>

        <app-spacer [bottom]="48"></app-spacer>

        <div class="row">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <app-button (onClick)="toggleRefuseModal(true)" [outline]="true"
                    [disabled]="sending" [loading]="sending" type="submit">
                    Invalidar questionário
                </app-button>

                <app-spacer [right]="16"></app-spacer>

                <app-button (onClick)="checkProfile()"
                    [disabled]="sending || !form.valid" [loading]="sending"
                    type="submit">
                    Enviar dados
                </app-button>
            </div>
        </div>

        <app-spacer [bottom]="48"></app-spacer>


        <div class="row align-items-center"
            *ngIf="data && (data.score || not_declared)">
            <!-- <div class="col-12 col-lg-4 points-profile">
                <span class="points-amount">
                    {{not_declared ? '0' : data.score}}
                    pontos
                </span>
                <app-spacer [bottom]="4"></app-spacer>

                <div class="points-separator"></div>
                <app-spacer [bottom]="4"></app-spacer>
            </div> -->

            <div class="col-12">
                <p class="points-description text-right">Seu perfil é</p>

                <p class="points-name text-right">
                    {{getRiskLabel()}}
                </p>
            </div>

            <div class="col-12">
                <span class="basic-content-subtitle text-right">
                    Data de início: {{data.start_date | date: 'dd/MM/yyyy' ??
                    '-'}}
                </span>
                <span class="basic-content-subtitle text-right">
                    Data de validade: {{data.validity | date: 'dd/MM/yyyy' ??
                    '-'}}
                </span>
            </div>
        </div>
    </div>
</div>

<app-warning-modal [active]="redirectWarning" [hideCloseButton]="true">
    <h3>Atenção</h3>
    <app-spacer [bottom]="24"></app-spacer>

    <p>Para prosseguir, é necessário completar a etapa
        <strong>Dados complementares: Geral.</strong>
    </p>

    <app-spacer [bottom]="56"></app-spacer>

    <app-button class="add-button" (onClick)="redirectUser()" [center]="true">
        ok
    </app-button>
</app-warning-modal>

<app-warning-modal [active]="refuseModal" [hideCloseButton]="true">
    <ng-container *ngIf="showConfirmRefuse">
        <h3>Confirme sua decisão?</h3>
        <app-spacer [bottom]="24"></app-spacer>

        <p>
            Caso prossiga com a invalidação do questionário, seu perfil de
            investidor será definido como Não declarado.
        </p>
        <br>
        <p>
            Deseja continuar?
        </p>

        <app-spacer [bottom]="56"></app-spacer>

        <app-button [center]="true" class="add-button" (onClick)="refuseQuiz()"
            [outline]="true">
            Sim
        </app-button>

        <app-spacer [bottom]="8"></app-spacer>

        <app-button class="add-button" (onClick)="toggleRefuseModal(false)"
            [center]="true">
            Não
        </app-button>
    </ng-container>

    <ng-container *ngIf="!showConfirmRefuse">
        <h3>Atenção</h3>
        <app-spacer [bottom]="24"></app-spacer>
        <p>
            É necessário responder as questões de avaliação de perfil de
            investidor.
        </p>
        <br>
        <p>
            Deseja retornar e responder as questões?
        </p>

        <app-spacer [bottom]="56"></app-spacer>

        <app-button (onClick)="showConfirmRefuse = true" [center]="true"
            class="add-button" [outline]="true">
            Não
        </app-button>

        <app-spacer [bottom]="8"></app-spacer>

        <app-button class="add-button" (onClick)="toggleRefuseModal(false)"
            [center]="true">
            Sim
        </app-button>
    </ng-container>
</app-warning-modal>

<app-warning-modal [active]="showDisqualificationModal"
    [hideCloseButton]="true">
    <h3>Atenção</h3>
    <app-spacer [bottom]="24"></app-spacer>
    <p>
        Seu perfil de investidor não se enquadra nos critérios de público alvo
        do fundo de interesse.
    </p>
    <br>
    <p>
        Deseja continuar mesmo assim?
    </p>
    <app-spacer [bottom]="56"></app-spacer>

    <app-button (onClick)="sendData(true)" [center]="true" class="add-button"
        [disabled]="sending" [outline]="true">
        Sim
    </app-button>

    <app-spacer [bottom]="8"></app-spacer>

    <app-button class="add-button" (onClick)="showDisqualificationModal = false"
        [disabled]="sending" [center]="true">
        Não
    </app-button>
</app-warning-modal>