import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GetHelper } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';

export type PersonDeclarationsValues = {
  authorized_attorney_representative: boolean;

  politically_exposed_person_relationship: boolean;
  pepExplain: string;

  pepRelationship: boolean;
  document_number: string;
  name: string;
  bond: string;

  third_party_operation: boolean;
  document_number_person_or_company: string;
  name_or_company_name: string;

  linked_cm_capital: boolean;

  politically_exposed: boolean;
  description_politically: string;

  declare_and_authorize: boolean;
  id: number;
};

@Component({
  selector: 'app-signup-unified-person-declarations',
  templateUrl: './signup-unified-person-declarations.component.html',
  styleUrls: ['./signup-unified-person-declarations.component.scss'],
})
export class SignupUnifiedPersonDeclarationsComponent
  implements OnInit, OnDestroy
{
  form = this.formBuilder.group({
    authorized_attorney_representative: [false, []],
    qualification: [null, []],

    politically_exposed_person_relationship: [false, []],
    pepExplain: [null, []],

    politically_exposed: [false, []],
    description_politically: [null, []],

    third_party_operation: [false, []],
    document_number_person_or_company: [null, []],
    name_or_company_name: [null, []],

    linked_cm_capital: [false, []],

    declare_and_authorize: [false, [Validators.requiredTrue]],
  });

  pepForm = this.formBuilder.group({
    document_number: [null, []],
    name: [null, []],
    bond: [null, []],
  });

  columns: TableColumn<any>[] = [
    {
      name: 'CPF',
      cell: (row) => row.document_number,
    },
    { name: 'Nome', cell: (row) => row.name },
    {
      name: 'Vínculo',
      cell: (row) => row.bond,
    },
  ];

  data: PersonDeclarationsValues;

  qualificationOptions = [
    { label: 'Certificado', value: 'certified' },
    { label: 'Qualificado', value: 'qualified' },
    { label: 'Profissional', value: 'professional' },
  ];

  pepData = [];
  event: any;

  constructor(
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.event = this.signupService.sendPersonData.subscribe((value) => {
      if (value === 'declarations') {
        this.sendData();
      }
    });

    this.getData();

    this.form.statusChanges.subscribe((status) => this.changeFormStatus());

    this.form.controls.politically_exposed.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.description_politically.setValidators([
          Validators.required,
        ]);
      } else {
        this.form.controls.description_politically.setValue(null);
        this.form.controls.description_politically.clearValidators();
      }
      this.form.controls.description_politically.updateValueAndValidity();
    });

    this.form.controls.third_party_operation.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.document_number_person_or_company.setValidators([
          Validators.required,
        ]);
        this.form.controls.name_or_company_name.setValidators([
          Validators.required,
        ]);
      } else {
        this.form.controls.document_number_person_or_company.setValue(null);
        this.form.controls.name_or_company_name.setValue(null);
        this.form.controls.document_number_person_or_company.clearValidators();
        this.form.controls.name_or_company_name.clearValidators();
      }
      this.form.controls.document_number_person_or_company.updateValueAndValidity();
      this.form.controls.name_or_company_name.updateValueAndValidity();
    });

    this.form.controls.politically_exposed_person_relationship.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.pepForm.controls.document_number.setValidators([
            Validators.required,
          ]);
          this.pepForm.controls.name.setValidators([Validators.required]);
          this.pepForm.controls.bond.setValidators([Validators.required]);
        } else {
          this.pepForm.controls.document_number.setValue(null);
          this.pepForm.controls.name.setValue(null);
          this.pepForm.controls.bond.setValue(null);
          this.pepForm.controls.bond.clearValidators();
          this.pepForm.controls.name.clearValidators();
          this.pepForm.controls.document_number.clearValidators();
        }
        this.pepForm.controls.document_number.updateValueAndValidity();
        this.pepForm.controls.name.updateValueAndValidity();
        this.pepForm.controls.bond.updateValueAndValidity();

        this.changeFormStatus();
      }
    );
  }

  async getData() {
    try {
      const { data } = await this.api.get({
        route: 'api/registration/declaration_authorization/me',
        token: true,
      });

      this.form.patchValue(data);

      if (data.qualification === 'general_public')
        this.form.controls.qualification.setValue(null);

      this.data = data;

      if (data.politically_exposed_person_relationship) {
        let arr = [];

        for (let i = 0; i < data.name.length; i++) {
          arr.push({
            document_number: data.document_number[i],
            name: data.name[i],
            bond: data.bond[i],
          });
        }

        this.pepData = arr;
      }

      this.changeFormStatus();
    } catch (error) {
      console.log(error);
    }
  }

  async sendData() {
    this.signupService.loading = true;

    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        authorized_attorney_representative: valuesHelper.get(
          'authorized_attorney_representative'
        ),
        politically_exposed_person_relationship: valuesHelper.get(
          'politically_exposed_person_relationship'
        ),
        pepExplain: valuesHelper.get('pepExplain'),
        name: this.pepData.map((item) => item.name),
        document_number: this.pepData.map((item) => item.document_number),
        bond: this.pepData.map((item) => item.bond),
        third_party_operation: valuesHelper.get('third_party_operation'),
        document_number_person_or_company: valuesHelper.get(
          'document_number_person_or_company'
        ),
        qualification: valuesHelper.get('qualification') ?? 'general_public',
        name_or_company_name: valuesHelper.get('name_or_company_name'),
        linked_cm_capital: valuesHelper.get('linked_cm_capital'),
        declare_and_authorize: valuesHelper.get('declare_and_authorize'),
        politically_exposed: valuesHelper.get('politically_exposed'),
        description_politically: valuesHelper.get('description_politically'),
      };

      if (this.data && this.data.declare_and_authorize) {
        await this.api.put({
          route: `api/registration/declaration_authorization/${this.data.id}/`,
          token: true,
          body: payload,
        });
      } else {
        await this.api.post({
          route: 'api/registration/declaration_authorization/',
          token: true,
          body: payload,
        });
      }

      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.signupService.loading = false;
  }

  addPep() {
    const docAlreadyExists = this.pepData.find(
      (item) => item.document_number === this.pepForm.value.document_number
    );

    if (docAlreadyExists) {
      this.toast.show('info', 'Erro', 'CPF já cadastrado!');
      return;
    }

    this.pepData.push(this.pepForm.value);
    this.pepForm.reset();

    this.changeFormStatus();
  }

  getDisabled() {
    return !this.pepForm.valid;
  }

  removePep(index) {
    this.pepData.splice(index, 1);

    this.changeFormStatus();
  }

  changeFormStatus() {
    const status = this.form.status;

    console.log(
      'teste',
      status === 'VALID',
      this.pepData,
      this.pepData.length > 0
    );

    this.signupService.setPersonFormStatus(
      'declarations',
      this.form.controls.politically_exposed_person_relationship.value === true
        ? status === 'VALID' && this.pepData.length > 0
        : status === 'VALID'
    );
  }
}
