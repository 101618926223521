<div class="col-12" *ngIf="loading">
    <div class="row">
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{
                        height: '244px',
                        margin:'0 0 24px'
                    }">
            </ngx-skeleton-loader>
        </div>
    </div>
</div>

<div class="row" *ngIf="!loading">
    <div class="col-12">
        <div class="dash-card card-padding-top">
            <app-empty-content [isVisible]="data.length === 0"
                title="Nenhum anexo encontrado"
                description="Não foi encontrado nenhum anexo nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">
            </app-empty-content>

            <div class="dash-card-body" *ngIf="data.length > 0">
                <div class="dash-card-scroll">
                    <table>
                        <thead>
                            <tr class="alternative-header">
                                <th>Nome</th>
                                <th>Responsável</th>
                                <th>Data de cadastro</th>
                                <th>Fim validade</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of data"
                                class="alternative-header">
                                <td style="max-width: 500px;">
                                    <div class="dash-card-item">
                                        <div>
                                            <span>
                                                <img
                                                    src="{{renderIcon(item.files[0]?.file)}}" />
                                            </span>
                                        </div>

                                        <app-spacer [right]="16"></app-spacer>

                                        <div class="dash-card-item-content">
                                            <div
                                                class="dash-card-item-content-title">
                                                {{item.label ?? '--'}}
                                            </div>
                                            <div
                                                class="dash-card-item-content-subtitle">
                                                {{item.files.length > 1 ?
                                                item.files.length + ' arquivos'
                                                : '1 arquivo'}}
                                            </div>
                                        </div>

                                    </div>
                                </td>
                                <td>
                                    <div class="dash-card-item">
                                        <div class="dash-card-item-content">
                                            <div
                                                class="dash-card-item-content-title">
                                                {{formatPartnerName(item.files[0])}}
                                            </div>
                                            <div
                                                class="dash-card-item-content-subtitle">
                                                {{formatAttachmentType(item.files[0].referent)}}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="dash-card-item">
                                        <div class="dash-card-item-content-title"
                                            *ngIf="item.files[0].date_due">
                                            {{ item.files[0].date_due |
                                            date:'dd/MM/yyyy' }}
                                        </div>

                                        <div class="dash-card-item-content-title"
                                            *ngIf="!item.files[0].date_due">
                                            {{
                                            getAttachmentDate('start',
                                            item.files[0].date_created) }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="dash-card-item">
                                        <div class="dash-card-item-content-title"
                                            *ngIf="item.files[0].end_date_due">
                                            {{ item.files[0].end_date_due |
                                            date:'dd/MM/yyyy' }}
                                        </div>

                                        <div class="dash-card-item-content-title"
                                            *ngIf="!item.files[0].end_date_due">
                                            {{
                                            getAttachmentDate('end',
                                            item.files[0].date_created) }}
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <div
                                        class="d-flex align-items-center justify-content-end">
                                        <!-- <a href="{{item.files[0].file}}"
                                            download target="_blank">
                                            <button class="button">
                                                <i
                                                    class="fas fa-cloud-download-alt"></i>
                                            </button>
                                        </a>
                                        <app-spacer [right]="19"></app-spacer> -->
                                        <button class="button"
                                            (click)="openFile(item.files)">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>
    </div>
</div>

<form [formGroup]="reproveForm">
    <app-side-modal [active]="reproveModal" title="Solicitar novo documento"
        (onClose)="closeModal()" paddingBottom="0">
        <div class="container">
            <div class="row">
                <div class="col-12 modal-text-title">
                    <h3>Nome do documento</h3>
                    <p>{{selectedAttachment?.type?.name}}</p>
                </div>

                <div class="col-12">
                    <app-input type="textarea" [group]="reproveForm"
                        controlName="description" [rows]="4"
                        label="Esclareça como deve ser o documento esperado"
                        [noResize]="true">
                    </app-input>

                    <app-spacer [bottom]="48"></app-spacer>
                </div>
            </div>

            <div class="row button-sticky">
                <div class="col-12 col-lg-6">
                    <app-button (onClick)="closeModal()" class="add-button"
                        [outline]="true">
                        Cancelar
                    </app-button>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="text-right">
                        <app-button (onClick)="handleReproveDocument()"
                            class="add-button"
                            [disabled]="this.reproveForm.invalid">
                            Reprovar documento
                        </app-button>
                    </div>
                </div>
            </div>
        </div>
    </app-side-modal>
</form>

<app-warning-modal [active]="warningModal" (onClose)="closeModal()">
    <h3>Atenção</h3>
    <app-spacer [bottom]="24"></app-spacer>
    <p>Você tem certeza que deseja reprovar o documento Comprovante de
        residência enviado por SOCINAL S.A?</p>
    <app-spacer [bottom]="56"></app-spacer>

    <app-button (onClick)="openReproveModal()" [center]="true" [danger]="true">
        Sim, quero reprovar agora
    </app-button>
    <app-spacer [bottom]="8"></app-spacer>
    <app-button (onClick)="closeModal()" [center]="true" class="add-button">
        Não, pode manter
    </app-button>
</app-warning-modal>

<app-modal #modalRoot class="modal-demo">
    <ng-container class="app-modal-header">
        <ng-container *ngIf="selectedFile">
            <svg-icon src="assets/images/icon_drag_indicator.svg"></svg-icon>
            <div class="text-center">{{modalFiles[0].type?.name}}</div>
            <div></div>
        </ng-container>
    </ng-container>

    <ng-container class="app-modal-body">
        <ng-container *ngIf="selectedFile">
            <img [src]="selectedFile.url" *ngIf="selectedFile.isImg"
                style="width:100%">

            <iframe [src]="selectedFile.url" *ngIf="!selectedFile.isImg"
                frameborder="0" height="100%" width="100%">
            </iframe>
        </ng-container>
    </ng-container>

    <ng-container class="app-modal-footer">
        <div class="attachment-modal-navigation">
            <button class="attachment-modal-navigation-arrow"
                (click)="setPrevAttachment()" [disabled]="getDisabled('prev')">
                <i class="fas fa-angle-left"></i>
            </button>

            <div class="pl-3 pr-3">{{selectedFile?.name ?
                urlDecoding(selectedFile.name) : ''}}</div>

            <button class="attachment-modal-navigation-arrow"
                (click)="setNextAttachment()" [disabled]="getDisabled('next')">
                <i class="fas fa-angle-right"></i>
            </button>
        </div>
    </ng-container>
</app-modal>