import { Component, OnInit } from '@angular/core';

import Utils from 'src/app/helpers/utils';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-approval-declarations',
  templateUrl: './dashboard-approval-declarations.component.html',
  styleUrls: ['./dashboard-approval-declarations.component.scss'],
})
export class DashboardApprovalDeclarationsComponent implements OnInit {
  approval: UserApprovalResponse;
  data = null;
  loading = true;

  pepData: CompanyComplementaryPepListValues[] = [];
  pepColumns: TableColumnList<CompanyComplementaryPepListValues>[] = [
    {
      name: 'Nome',
      cell: (row) => `
    <span>${row.name}</span>
    <span class="app-table-details-body-row-subtitle">${row.document_number}</span>
  `,
    },
    { name: 'Vínculo', cell: (row) => row.bond },
  ];

  qualificationOptions = [
    { label: 'Certificado', value: 'certified' },
    { label: 'Qualificado', value: 'qualified' },
    { label: 'Profissional', value: 'professional' },
  ];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    if (this.authService.approvalData.complementary_data) {
      this.approval = this.authService.approvalData.complementary_data;
      this.setData();
      this.loading = false;
    }
  }

  setData() {
    this.data = this.approval.register.declaration_authorization
      ? this.approval.register.declaration_authorization[0]
      : null;

    if (this.data.politically_exposed_person_relationship) {
      let arr = [];

      for (let i = 0; i < this.data.name.length; i++) {
        arr.push({
          document_number: this.data.document_number[i],
          name: this.data.name[i],
          bond: this.data.bond[i],
        });
      }

      this.pepData = arr;
    }
  }

  formatBrl(amount: number | string) {
    const formatted = Utils.formatBrl(Number(amount));

    return formatted;
  }

  renderLabel(slug: string) {
    const finded = this.qualificationOptions.find(
      (item) => item.value === slug
    );

    return finded?.label ?? 'Público geral';
  }
}
