<div class="row">
    <div class="col-12">
        <div class="dash-card card-padding-top">
            <app-empty-content [isVisible]="bonds.length === 0"
                title="Nenhum vinculo encontrado"
                description="Não foi encontrado nenhum vinculo nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">
            </app-empty-content>

            <div class="dash-card-body" *ngIf="bonds.length > 0">
                <div class="dash-card-scroll">
                    <table>
                        <thead>
                            <tr class="alternative-header">
                                <th>Fundo</th>
                                <th *ngIf="!isShareholder">Coobrigação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let bond of bonds">
                                <td>
                                    <div class="dash-card-item">
                                        <div class="dash-card-item-initials">
                                            <span>{{getFundInitials(bond)}}</span>
                                        </div>

                                        <app-spacer [right]="16"></app-spacer>

                                        <div class="dash-card-item-content">
                                            <div
                                                class="dash-card-item-content-title">
                                                {{bond.name ?? bond.short_name}}
                                            </div>
                                            <div
                                                class="dash-card-item-content-subtitle">
                                                {{bond.document}} •
                                                {{bond.anbima_classification_label}}
                                            </div>
                                        </div>

                                    </div>
                                </td>
                                <td style="padding:0 10px"
                                    *ngIf="!isShareholder">
                                    {{bond.coobligation ? 'Sim' : 'Não'}}
                                </td>
                            </tr>

                            <tr *ngIf="bonds.length === 0">
                                <td colspan="3">
                                    <span
                                        class="dash-card-item-content-subtitle">Nenhum
                                        vínculo
                                        adicionado</span>
                                </td>
                            </tr>
                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>