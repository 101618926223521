<div class="row">
    <div class="col-12">
        <div class="dash-details-card">
            <div class="dash-details-card-header text-right">
                <div></div>

                <div *ngIf="applicableRole === 'ADM'">
                    <!-- <app-button [inverted]="true" (click)="addNewDocument()" class="mr-2">
            Criar minuta
          </app-button> -->

                    <app-button (click)="toggleLinkModal(true)">
                        Vincular minuta
                    </app-button>

                </div>
            </div>

            <app-spacer [bottom]="30"></app-spacer>
            <table class="bordered" *ngIf="data.length > 0">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Destino</th>
                        <th>Início da vigência</th>
                        <th>Fim da vigência</th>
                        <th>Última atualização</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of data">
                        <td>{{item.file}}</td>
                        <td>{{formatApplicable(item.applicable)}}</td>
                        <td>{{formatDate(item.initial_date,false)}}</td>
                        <td>{{formatDate(item.final_date,false)}}</td>
                        <td>{{formatDate(item.date_updated)}}</td>
                        <td>
                            <div class="dash-card-item-buttons">
                                <!-- <button class="dash-item-button" (click)="handleDelete(item.id)">
                  <i class="fas fa-trash"></i>
                </button>
                <app-spacer [right]="16"></app-spacer>

                <button class="dash-item-button" (click)="handleEdit(item.id)">
                  <i class="fas fa-pen"></i>
                </button>
                <app-spacer [right]="16"></app-spacer> -->

                                <button class="dash-item-button"
                                    (click)="handleOpenVisualizer(item.id)">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <app-spacer [bottom]="28"></app-spacer>
            </table>

            <div *ngIf="data.length === 0" class="card-padding-bottom">
                <app-empty-content [isVisible]="true"
                    title="Nenhum documento encontrado"
                    description="Não foi encontrado nenhum documento nesse cadastro"
                    icon="/assets/images/forgot-password-icon2.png">
                </app-empty-content>
            </div>
        </div>
        <app-spacer [bottom]="24"></app-spacer>
    </div>
</div>

<app-document-visualizer-modal [active]="showDocument" [file]="document"
    (onClose)="closeModal()" *ngIf="document">
</app-document-visualizer-modal>


<app-side-modal [active]="linkModal" (onClose)="toggleLinkModal(false)"
    title="Vincular minuta" paddingBottom="30">
    <form [formGroup]="linkForm">
        <div class="row">

            <div class="col-12">
                <app-select [items]="draftsItems" label="Seleciona as minutas"
                    [group]="linkForm" controlName="draft"
                    placeholder="Selecione">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">

            <div class="col-12">
                <div class="text-right">
                    <app-button (onClick)="submitForm()" class="add-button">
                        Vincular minuta
                    </app-button>
                </div>
            </div>

        </div>
    </form>
</app-side-modal>