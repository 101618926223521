<div class="row">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <ng-container *ngIf="loading">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-3 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loading">
                <div class="dash-details-card-body">
                    <ng-container *ngIf="data">
                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    PRAZO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{getChoicesName('tax_classification_deadline_choices',
                                    data.tax_classification_deadline)?.label
                                    ?? '--'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    FORMA DE TRIBUTAÇÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{getChoicesName('tax_classification_tax_choices',
                                    data.tax_classification_tax)?.label ??
                                    '--'}}
                                </span>
                            </div>

                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CLASSIFICAÇÃO DOS RISCOS
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.risk_classification ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DIREITOS CREDITÓRIOS REGISTRADOS
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.credit_rights_will_be_granted ?
                                    'Sim' : 'Não'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    TIPO DE DIREITOS CREDITÓRIOS
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.creditors_rights ?
                                    fromatCreditorsRight(data.creditors_rights)
                                    :
                                    '--'}}
                                </span>
                            </div>

                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DESCRIÇÃO ANALÍTICA DOS DIREITOS CREDITÓRIOS
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.analytical_description_of_credit_rights
                                    ?? '--'
                                    }}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CESSÃO MAIOR QUE 400 RECEBÍVEIS POR
                                    TRIMESTRE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.assignment_expectation_greater_than_400
                                    ? 'Sim' : 'Não'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>


                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="Emissão de cotas com entrega de ativos">
                                </app-label>
                            </div>
                        </div>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="row">
                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    PARÂMETRO PARA DEFINIÇÃO DO VALOR JUSTO DOS
                                    ATIVOS
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.quota_fair_value |
                                    currency:'BRL':true ?? '--'}}
                                </span>
                            </div>
                        </div>
                    </ng-container>

                    <app-empty-content [isVisible]="!data"
                        title="Nenhum dado complementar"
                        description="Não foi encontrado nenhum dado complementar nesse cadastro"
                        icon="/assets/images/forgot-password-icon2.png">
                    </app-empty-content>
                </div>
            </ng-container>
        </div>
    </div>
</div>