import { ApiService } from 'src/app/services/api/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { SignupService } from 'src/app/services/signup/signup.service';
import { GetHelper } from 'src/app/helpers/utils';
import { ToastService } from 'src/app/services/toast/toast.service';
import { mainActivityItems, riskItems, webCopItems } from 'src/app/data/risk';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-signup-unified-company-complementary-risk',
  templateUrl: './signup-unified-company-complementary-risk.component.html',
  styleUrls: ['./signup-unified-company-complementary-risk.component.scss'],
})
export class SignupUnifiedCompanyComplementaryRiskComponent implements OnInit {
  @Input() userRole: string;

  form = this.formBuilder.group({
    operating_limit: [null, [Validators.required, Validators.min(1)]],
    web_corp_migrated_framework: [null, [Validators.required]],
    risk: [null, [Validators.required]],
    observations: [null, []],
  });

  loading = true;
  riskID = null;
  event: any;

  riskOptions = riskItems;
  webCopOptions = webCopItems;
  mainAcityOptions = mainActivityItems;

  constructor(
    private formBuilder: FormBuilder,
    public signupService: SignupService,
    private api: ApiService,
    private toast: ToastService,
    private authService: AuthService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.signupService.choicesFetchedEvent.subscribe((choices) => {
      this.loading = false;
    });

    this.event = this.signupService.sendComplementaryCompanyData.subscribe(
      (value) => {
        if (value === 'complementaryRisk') {
          this.sendData();
        }
      }
    );

    this.getDataAndFillForm();

    this.signupService.fillFormEvent.subscribe(() => {
      this.form.patchValue({
        web_corp_migrated_framework: 1,
        risk: 'low',
        operating_limit: 1000,
      });
    });

    this.form.valueChanges.subscribe(() => {
      this.signupService.setCompanyFormStatus(
        'complementaryRisk',
        this.form.valid
      );
    });
  }

  async getDataAndFillForm() {
    this.loading = true;
    try {
      const id = this.authService.user.active_register.register.id;

      const res = await this.api.get<any>({
        route: 'api/registration/risk_information/',
        token: true,
        params: {
          register: id,
        },
      });

      this.riskID = res.id ?? null;

      if (!res.data) {
        this.form.patchValue({
          operating_limit: res.operating_limit,
          web_corp_migrated_framework: Number(res.web_corp_migrated_framework),
          risk: res.risk,
          observations: res.observations,
        });
      }
    } catch (error) {}
    this.loading = false;
  }

  async sendData() {
    try {
      const valuesHelper = new GetHelper(this.form.value);
      const id = this.authService.user.active_register.register.id;
      const payload = {
        ...valuesHelper.getRemainingObject(),
        register: id,
      };

      const response = this.riskID
        ? await this.api.put<ApiResponse<BankResponse>>({
            route: `api/registration/risk_information/${this.riskID}/`,
            token: true,
            body: payload,
          })
        : await this.api.post<ApiResponse<BankResponse>>({
            route: 'api/registration/risk_information/',
            token: true,
            body: payload,
          });

      this.toast.show('info', 'Sucesso', response.message);

      const isFund =
        this.authService.user.active_register.register.role.slug.includes(
          '-FI'
        );

      if (isFund) {
        this.signupService.setCompanyStep('complementaryAdmin');
        this.signupService.changeCompanyStepEvent.emit('admin');
      } else {
        this.signupService.setCompanyStep('complementaryRepresentatives');
        this.signupService.changeCompanyStepEvent.emit('representatives');
      }
    } catch (error) {}
  }
}
