<ng-container *ngIf="!maintence">

    <router-outlet></router-outlet>

    <app-toast></app-toast>

    <button *ngIf="authService.user != undefined && debugForm"
        class="button-logoff" (click)="logoff()">
        <i class="fas fa-door-open"></i>
    </button>

    <button *ngIf="debugForm || fillButtonUnclocked" class="button-fill"
        (click)="fillForm()">
        <i class="fas fa-pen"></i>
    </button>
</ng-container>

<ng-container *ngIf="maintence">
    <app-maintenance></app-maintenance>
</ng-container>