import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-approval-documents',
  templateUrl: './dashboard-approval-documents.component.html',
  styleUrls: ['./dashboard-approval-documents.component.scss'],
})
export class DashboardApprovalDocumentsComponent implements OnInit {
  @Input() approval: any;

  searchWitnessForm = this.formBuilder.group({
    document: [null, [Validators.required]],
  });

  witnessForm = this.formBuilder.group({
    name: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
  });

  witnesses: SelectItem[] = [];
  clearAttachments: boolean = false;

  data: Contracts[] = [
    // {
    //   name: 'Contrato de compra e venda',
    //   status: 'Aguardando assinatura',
    //   id: 1,
    //   witness: [
    //     {
    //       name: 'João da Silva',
    //       id: 1,
    //       document: '123.456.789-00',
    //       email: 'email@exemplo.com',
    //     },
    //   ],
    // },
    // {
    //   id: 2,
    //   name: 'Contrato de compra e venda',
    //   status: 'Aguardando assinatura',
    //   witness: [
    //     {
    //       name: 'Maria da Silva',
    //       id: 1,
    //       document: '123.456.789-00',
    //       email: 'email@exemplo.com',
    //     },
    //   ],
    // },
  ];

  documents: any[] = [];

  selectedData: Contracts | undefined;

  foundedWitnessesLabel: string = '';
  foundedWitnesses: Witness = null;
  notFound: boolean = false;

  witnessList: Witness[] = [];

  showDocumentDetailsModal: boolean = false;

  newAttachments: FilePicked[] = [];
  loading: boolean = false;
  showDocumentModal: boolean = false;
  showAttachmentModal: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.getData();

    this.handleGetDocuments();

    this.searchWitnessForm.controls.document.valueChanges.subscribe((value) => {
      if (value && value.length < 14 && this.notFound) {
        this.notFound = false;
        this.witnessForm.reset();
      }
    });
  }

  toggleDocumentModal() {
    this.showDocumentModal = !this.showDocumentModal;

    if (!this.showDocumentModal) {
      this.witnessList = [];
      this.foundedWitnessesLabel = '';
      this.foundedWitnesses = null;
      this.notFound = false;
      //   this.witnesses = [];
      this.searchWitnessForm.reset();
      this.witnessForm.reset();
    }
  }

  async handleGetDocuments() {
    let documents_slugs = [
      'us-person-declaration',
      'suitability_pf',
      'suitability_pj',
      'declaration_investor',
      'registration_form',
      'refusal_term',
      'disqualification',
    ];

    if (this.approval.register.role.slug.includes('PJ')) {
      documents_slugs = documents_slugs.filter(
        (slug) => slug !== 'suitability_pf'
      );
    } else {
      documents_slugs = documents_slugs.filter(
        (slug) => slug !== 'suitability_pj'
      );
    }

    await documents_slugs.forEach(async (slug) => {
      await this.getDocuments(slug);
    });
  }

  async getDocuments(slug: string) {
    const res = await this.api.get({
      route: 'api/document/',
      params: {
        register: this.approval.register.id,
        type: slug,
      },
      token: true,
    });

    // if (!res) return;

    this.documents.push({
      slug,
      data: res.data ?? '-',
    });
  }

  toggleAttachmentModal() {
    this.clearAttachments = !this.showAttachmentModal;
    this.showAttachmentModal = !this.showAttachmentModal;
    this.newAttachments = [];
  }

  toggleDocumentDetailsModal() {
    this.showDocumentDetailsModal = !this.showDocumentDetailsModal;
    this.selectedData = undefined;
  }

  getDocumentLabel(slug: string) {
    switch (slug) {
      case 'us-person-declaration':
        return 'Declaração US Person (FATCA)';

      case 'suitability_pf':
        return 'Suitability';

      case 'suitability_pj':
        return 'Suitability';

      case 'declaration_investor':
        return 'Declaração de Investidor';

      case 'refusal_term':
        return 'Termo de Recusa';

      case 'registration_form':
        return 'Ficha cadastral';

      case 'disqualification':
        return 'Termo de Desenquadramento';
      default:
        return '';
    }
  }

  openUsPerson(file: string) {
    window.open(file, '_blank');
  }

  formateDate(date: string) {
    const day = date.split('T').shift()?.split('-').reverse().join('/');
    const hours = date.split('T').pop()?.slice(0, 5);

    return `${day} às ${hours}`;
  }

  async generateDocument() {
    this.toggleDocumentModal();

    // this.toast.show(
    //   'info',
    //   'Sucesso!',
    //   'Documento gerado com sucesso! Aguarde a aprovação.'
    // );

    try {
      const res = await this.api.post({
        route: `api/approvals/user_approval/${this.approval.id}/generate_document/`,
        body: {
          witness: this.witnessList,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getData() {
    try {
      const res = await this.api.get({
        route: 'api/registration/witnesses/',
        token: true,
      });

      this.witnesses = res.data.map((witness) => {
        return {
          value: witness.id,
          label: `${witness.name} | ${witness.document_number}`,
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  getDisabled() {
    return this.witnessList.length === 0;
  }

  getAttachmentDisabled() {
    return this.newAttachments.length === 0;
  }

  handleOpenVisualizer(id: number) {
    this.showDocumentDetailsModal = true;

    this.selectedData = this.data.find((doc) => doc.id === id);
  }

  formatWitnesses(witnesses: Witness[]) {
    const formatNames = witnesses.map((witness) => witness.name);

    return formatNames.join(', ');
  }

  setFiles(files: FilePicked[]) {
    this.newAttachments = [...files];
  }

  async searchWitness(event: any) {
    if (event.target.value.length === 14) {
      this.loading = true;

      try {
        const res = await this.api.get({
          route: 'api/registration/witnesses/',
          token: true,
          params: {
            document_number: event.target.value,
          },
        });

        if (res.data.length === 0) {
          this.toast.show(
            'info',
            'Testemunha não encontrada',
            'Preencha os dados abaixo para adicionar uma nova testemunha'
          );
          this.foundedWitnessesLabel = '';
          this.foundedWitnesses = null;
          this.witnessForm.reset();
          this.loading = false;
          this.notFound = true;

          return;
        } else {
          const alreadyHave = this.witnessList.find(
            (witness) => witness.document === res.data[0].document_number
          );

          if (alreadyHave) {
            this.toast.show(
              'info',
              'Testemunha já adicionada',
              'A testemunha já foi adicionada, por favor, selecione outra.'
            );
            this.foundedWitnessesLabel = '';
            this.foundedWitnesses = null;
            this.searchWitnessForm.reset();
            this.loading = false;
            this.notFound = false;
            return;
          }

          this.foundedWitnessesLabel = `${res.data[0].name} | ${event.target.value}`;
          this.foundedWitnesses = {
            id: res.data[0].id,
            name: res.data[0].name,
            document: res.data[0].document_number,
            email: res.data[0].email,
          };
          this.notFound = false;
        }
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    }
  }

  handleAddWitnesses(approveWitness: boolean) {
    if (approveWitness) {
      this.witnessList.push(this.foundedWitnesses);
    }
    this.foundedWitnessesLabel = '';
    this.foundedWitnesses = null;
    this.searchWitnessForm.reset();
  }

  async addNewWitnesss() {
    try {
      const payload = {
        name: this.witnessForm.value.name,
        document_number: this.searchWitnessForm.value.document,
        email: this.witnessForm.value.email,
      };

      const res = await this.api.post({
        route: 'api/registration/witnesses/',
        body: payload,
        token: true,
      });

      this.witnessList.push({
        id: res.id,
        name: res.name,
        document: res.document_number,
        email: res.email,
      });

      this.witnessForm.reset();
      this.searchWitnessForm.reset();
      this.notFound = false;

      this.toast.show('info', 'Sucesso', 'Testemunha adicionada com sucesso!');
    } catch (error) {
      console.log(error);
    }
  }

  removeWitness(idx: number) {
    this.witnessList.splice(idx, 1);
  }
}
