import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MaskPipe } from 'ngx-mask';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-approval-risk',
  templateUrl: './dashboard-approval-risk.component.html',
  styleUrls: ['./dashboard-approval-risk.component.scss'],
})
export class DashboardApprovalRiskComponent implements OnInit {
  data: RiskResponse;
  loading = true;

  constructor(
    private maskPipe: MaskPipe,
    private api: ApiService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.authService.approvalData.risk) {
      this.data = this.authService.approvalData.risk;
      this.loading = false;
    } else {
      this.getApprovalData();
    }
  }

  async getApprovalData() {
    try {
      const req_params = {
        register: this.route.snapshot.params.id,
        tab: 'risk',
      };

      if (this.authService.approvalData.revision) {
        req_params['revision'] = this.authService.approvalData.revision;
        req_params['version'] = this.authService.approvalData.version;
      }

      const data = await this.api.get<any[]>({
        route: `api/v2/approvals/version/`,
        token: true,
        params: req_params,
      });

      this.data = data[0].register.risk ?? null;

      this.authService.approvalData.risk = data[0].register.risk ?? null;
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  formatBrl(amount: number | string) {
    const formatted = Utils.formatBrl(Number(amount));

    return formatted;
  }
}
