export const riskItems: SelectItem[] = [
  { value: 'high', label: 'Alto' },
  { value: 'low', label: 'Baixo' },
  { value: 'average', label: 'Médio' },
  { value: 'not_registered', label: 'Não cadastrado' },
];

export const webCopItems: SelectItem[] = [
  { value: 1, label: 'Enquadrado' },
  { value: 2, label: 'Desenquadrado' },
  { value: 3, label: 'Não informado' },
];

export const mainActivityItems: SelectItem[] = [
  {
    value: 1,
    label:
      '101 - Membro do Poder Executivo (Presidente da República, Vice-Presidente da República, Ministro de Estado, Governador, Vice-Governador, Prefeito, Vice-Prefeito',
  },
  {
    value: 2,
    label:
      '102 - Membro do Poder Judiciário (Ministro, Juiz e Desembargador) e de Tribunal de Contas (Ministro e Conselheiro',
  },
  {
    value: 3,
    label:
      '103 - Membro do Poder Legislativo (Senador, Deputado Federal, Deputado Estadual e Vereador',
  },
  {
    value: 4,
    label: '104 - Membro do Ministério Público (Procurador e Promotor',
  },
  {
    value: 5,
    label:
      '105 - Dirigente superior da administração pública (ocupante de cargo de direção, chefia, assessoria e de natureza especial), inclusive os das fundações públicas e autarquias',
  },
  { value: 6, label: '106 - Diplomata e afins' },
  { value: 7, label: '107 - Servidor das carreiras do Poder Legislativo' },
  { value: 8, label: '108 - Servidor das carreiras do Ministério Público' },
  {
    value: 9,
    label:
      '109 - Servidor das carreiras do Poder Judiciário, Oficial de Justiça, Auxiliar, Assistente e Analista Judiciário',
  },
  {
    value: 10,
    label:
      '110 - Advogado do setor público, Procurador da Fazenda, Consultor Jurídico, Procurador de autarquias e fundações públicas, Defensor Público',
  },
  {
    value: 11,
    label: '111 - Servidor das carreiras de auditoria fiscal e de fiscalização',
  },
  {
    value: 12,
    label: '112 - Servidor das carreiras do Banco Central, CVM e Susep',
  },
  {
    value: 13,
    label:
      '113 - Delegado de Polícia e outros servidores das carreiras de polícia, exceto militar',
  },
  {
    value: 14,
    label:
      '114 - Servidor das carreiras de gestão governamental, analista, gestor e técnico de planejamento',
  },
  { value: 15, label: '115 - Servidor das carreiras de ciência e tecnologia' },
  {
    value: 16,
    label:
      '116 - Servidor das demais carreiras da administração pública direta, autárquica e fundacional',
  },
  { value: 17, label: '117 - Titular de Cartório' },
  {
    value: 18,
    label:
      '118 - Dirigente ou administrador de partido político, organização patronal, sindical, filantrópica e religiosa',
  },
  {
    value: 19,
    label:
      '120 - Dirigente, presidente e diretor de empresa industrial, comercial ou prestadora de serviços',
  },
  {
    value: 20,
    label:
      '121 - Presidente e diretor de empresa pública e sociedade de economia mista',
  },
  {
    value: 21,
    label:
      '130 - Gerente ou supervisor de empresa industrial, comercial ou prestadora de serviços',
  },
  {
    value: 22,
    label:
      '131 - Gerente ou supervisor de empresa pública e sociedade de economia mista',
  },
  {
    value: 23,
    label:
      '140 - Presidente, diretor, gerente e supervisor de organismo internacional e de organização não-governamental',
  },
  { value: 24, label: '211 - Matemático, estatístico, atuário e afins' },
  {
    value: 25,
    label:
      '212 - Analista de sistemas, desenvolvedor de software, administrador de redes e bancos de dados e outros especialistas em informática (exceto técnico',
  },
  {
    value: 26,
    label:
      '213 - Físico, químico, meteorologista, geólogo, oceanógrafo e afins',
  },
  { value: 27, label: '214 - Engenheiro, arquiteto e afins' },
  {
    value: 28,
    label:
      '215 - Piloto de aeronaves, comandante de embarcações e oficiais de máquinas',
  },
  { value: 29, label: '221 - Biólogo, biomédico e afins' },
  { value: 30, label: '222 - Agrônomo e afins' },
  {
    value: 31,
    label: '224 - Profissional da educação física (exceto professor',
  },
  { value: 32, label: '225 - Médico' },
  { value: 33, label: '226 - Odontólogo' },
  {
    value: 34,
    label:
      '227 - Enfermeiro de nível superior, nutricionista, farmacêutico e afins',
  },
  {
    value: 35,
    label: '228 - Veterinário, patologista (veterinário) e zootecnista',
  },
  {
    value: 36,
    label:
      '229 - Fonoaudiólogo, fisioterapeuta, terapeuta ocupacional e afins (até o ano-calendário 2023',
  },
  { value: 37, label: '230 - Fonoaudiólogo (a partir do ano-calendário 2024' },
  { value: 38, label: '231 - Fisioterapeuta (a partir do ano-calendário 2024' },
  {
    value: 39,
    label: '232 - Terapeuta ocupacional (a partir do ano-calendário 2024',
  },
  { value: 40, label: '241 - Advogado' },
  { value: 41, label: '250 - Sociólogo e cientista político' },
  { value: 42, label: '251 - Antropólogo e arqueólogo' },
  {
    value: 43,
    label: '252 - Economista, administrador, contador, auditor e afins',
  },
  {
    value: 44,
    label:
      '253 - Profissional de marketing, de publicidade e de comercialização',
  },
  { value: 45, label: '254 - Psicanalista' },
  { value: 46, label: '255 - Psicólogo' },
  { value: 47, label: '256 - Geógrafo' },
  { value: 48, label: '257 - Historiador' },
  { value: 49, label: '258 - Assistente social e economista doméstico' },
  { value: 50, label: '259 - Filósofo' },
  { value: 51, label: '261 - Jornalista e repórter' },
  {
    value: 52,
    label: '263 - Sacerdote ou membro de ordens ou seitas religiosas',
  },
  { value: 53, label: '264 - Tradutor, intérprete, filólogo' },
  {
    value: 54,
    label: '265 - Bibliotecário, documentalista, arquivólogo, museólogo',
  },
  { value: 55, label: '266 - Escritor, crítico, redator' },
  { value: 56, label: '271 - Locutor, comentarista' },
  { value: 57, label: '272 - Ator, diretor de espetáculos' },
  { value: 58, label: '273 - Cantor e compositor' },
  {
    value: 59,
    label: '274 - Músico, arranjador, regente de orquestra ou coral',
  },
  {
    value: 60,
    label:
      '275 - Desenhista industrial (designer), escultor, pintor artístico e afins',
  },
  { value: 61, label: '276 - Cenógrafo, decorador de interiores' },
  { value: 62, label: '277 - Empresário e produtor de espetáculos' },
  { value: 63, label: '279 - Outros profissionais do espetáculo e das artes' },
  { value: 64, label: '290 - Professor na educação infantil' },
  { value: 65, label: '291 - Professor do ensino fundamental' },
  { value: 66, label: '292 - Professor do ensino médio' },
  { value: 67, label: '293 - Professor do ensino profissional' },
  { value: 68, label: '294 - Professor do ensino superior' },
  { value: 69, label: '295 - Instrutor e professor de escolas livres' },
  { value: 70, label: '296 - Pedagogo, orientador educacional' },
  { value: 71, label: '311 - Técnico em ciências físicas e químicas' },
  {
    value: 72,
    label:
      '312 - Técnico em construção civil, de edificações e obras de infra-estrutura',
  },
  { value: 73, label: '313 - Técnico em eletro-eletrônica e fotônica' },
  { value: 74, label: '314 - Técnico em metalmecânica' },
  { value: 75, label: '316 - Técnico em mineralogia e geologia' },
  { value: 76, label: '317 - Técnico em informática' },
  { value: 77, label: '318 - Desenhista técnico e modelista' },
  {
    value: 78,
    label:
      '319 - Outros técnicos de nível médio das ciências físicas, químicas, engenharia e afins',
  },
  { value: 79, label: '320 - Técnico em biologia' },
  { value: 80, label: '321 - Técnico da produção agropecuária' },
  { value: 81, label: '322 - Técnico da ciência da saúde humana' },
  { value: 82, label: '323 - Técnico da ciência da saúde animal' },
  {
    value: 83,
    label:
      '324 - Técnico de laboratório, Raios-X e outros equipamentos e instrumentos de diagnóstico',
  },
  { value: 84, label: '325 - Técnico de bioquímica e da biotecnologia' },
  {
    value: 85,
    label: '328 - Técnico de conservação, dissecação e empalhamento de corpos',
  },
  {
    value: 86,
    label: '351 - Técnico das ciências administrativas e contábeis',
  },
  {
    value: 87,
    label:
      '352 - Técnico de inspeção, fiscalização e coordenação administrativa',
  },
  {
    value: 88,
    label:
      '353 - Agente de Bolsa de Valores, câmbio e outros serviços financeiros',
  },
  {
    value: 89,
    label:
      '354 - Agente e representante comercial, corretor, leiloeiro e afins',
  },
  { value: 90, label: '355 - Corretor e Administrador de Imóveis' },
  { value: 91, label: '371 - Técnico de serviços culturais' },
  {
    value: 92,
    label:
      '372 - Cinegrafista, fotógrafo e outros técnicos em operação de máquinas de tratamento de dados',
  },
  {
    value: 93,
    label: '373 - Técnico em operação de estações de rádio e televisão',
  },
  {
    value: 94,
    label:
      '374 - Técnico em operação de aparelhos de sonorização, cenografia e projeção',
  },
  { value: 95, label: '375 - Decorador e vitrinista' },
  {
    value: 96,
    label: '376 - Apresentador, artistas de artes populares e modelos',
  },
  { value: 97, label: '377 - Atleta, desportista e afins' },
  { value: 98, label: '391 - Outros técnicos de nível médio' },
  {
    value: 99,
    label:
      '410 - Bancário, economiário, escriturário, secretário, assistente e auxiliar administrativo',
  },
  {
    value: 100,
    label:
      '420 - Trabalhador de atendimento ao público, caixa, despachante, recenseador e afins',
  },
  {
    value: 101,
    label:
      '511 - Comissário de bordo, guia de turismo, agente de viagem e afins',
  },
  { value: 102, label: '512 - Trabalhador dos serviços domésticos em geral' },
  {
    value: 103,
    label: '513 - Trabalhador dos serviços de hotelaria e alimentação',
  },
  {
    value: 104,
    label:
      '514 - Trabalhador dos serviços de administração, conservação e manutenção de edifícios',
  },
  { value: 105, label: '515 - Trabalhador dos serviços de saúde' },
  {
    value: 106,
    label:
      '516 - Trabalhador dos serviços de embelezamento e cuidados pessoais',
  },
  {
    value: 107,
    label:
      '517 - Trabalhador dos serviços de proteção e segurança (exceto militar',
  },
  {
    value: 108,
    label:
      '518 - Motorista e condutor do transporte de passageiros (motorista de taxi, ônibus, pequena embarcação etc',
  },
  { value: 109, label: '519 - Outros trabalhadores de serviços diversos' },
  {
    value: 110,
    label:
      '529 - Vendedor e prestador de serviços do comércio, ambulante, caixeiro-viajante e camelô',
  },
  { value: 111, label: '610 - Produtor na exploração agropecuária' },
  { value: 112, label: '620 - Trabalhador na exploração agropecuária' },
  { value: 113, label: '630 - Pescador, caçador e extrativista florestal' },
  { value: 114, label: '640 - Operador de máquina agropecuária e florestal' },
  {
    value: 115,
    label: '710 - Trabalhador da indústria extrativa e da construção civil',
  },
  {
    value: 116,
    label: '720 - Trabalhador da transformação de metais e compósitos',
  },
  {
    value: 117,
    label: '730 - Trabalhador da fabricação e instalação eletro-eletrônica',
  },
  {
    value: 118,
    label: '740 - Montador de aparelhos e instrumentos de precisão e musicais',
  },
  { value: 119, label: '750 - Joalheiro, vidreiro, ceramista e afins' },
  {
    value: 120,
    label:
      '760 - Trabalhador das indústrias têxteis, do curtimento, do vestuário e das artes gráficas',
  },
  {
    value: 121,
    label: '770 - Trabalhador das indústrias de madeira e do mobiliário',
  },
  {
    value: 122,
    label:
      '780 - Condutor e operador de robôs, veículos de equipamentos de movimentação de carga e afins',
  },
  {
    value: 123,
    label:
      '810 - Trabalhador das indústrias química, petroquímica, borracha e plástico e afins',
  },
  {
    value: 124,
    label:
      '820 - Trabalhador de instalações siderúrgicas e de materiais de construção',
  },
  {
    value: 125,
    label:
      '830 - Trabalhador de instalações e máquinas de fabricação de celulose e papel',
  },
  {
    value: 126,
    label:
      '840 - Trabalhador da fabricação de alimentos, bebidas, fumo e de agroindústrias',
  },
  {
    value: 127,
    label:
      '860 - Operador de instalações de produção e distribuição de energia',
  },
  {
    value: 128,
    label: '870 - Trabalhador de outras instalações agroindustriais',
  },
  { value: 129, label: '900 - Trabalhador de reparação e manutenção' },
  { value: 130, label: '10 - Militar da Aeronáutica' },
  { value: 131, label: '20 - Militar do Exército' },
  { value: 132, label: '30 - Militar da Marinha' },
  { value: 133, label: '40 - Policial Militar' },
  { value: 134, label: '50 - Bombeiro Militar' },
  { value: 135, label: '0 - Sem ocupações' },
];
