import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from './services/api/api.service';
import { AuthService } from './services/auth/auth.service';
import { LoadingService } from './services/Loading/loading.service';
import { SignUpChoices, SignupService } from './services/signup/signup.service';
import { StorageService } from './services/storage/storage.service';
import { ToastService } from './services/toast/toast.service';
import { filter, map } from 'rxjs/operators';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import fakerbr from 'faker-br';
import { Title } from '@angular/platform-browser';
import UIDefaultTheme from 'src/themes';
import { PreviousUrlService } from './services/previous-url/previous-url.service';

import gitInfo from 'git-info';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Sistema de Cadastro de Clientes';

  previousUrl: string = null;
  currentUrl: string = null;

  complementary_fetched = false;

  debugForm = false;
  local = false;

  maintence = false;

  inputSequence = '';
  fillButtonUnclocked = false;

  constructor(
    public authService: AuthService,
    private storageService: StorageService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private apiService: ApiService,
    public signupService: SignupService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    public urlService: PreviousUrlService,
    private storage: StorageService
  ) {
    this.setupKeyboardListener();
    const permissions = this.storageService.get('permissions', true);
    this.authService.permissions = permissions;

    Sentry.init({
      enabled: true,
      // debug: this.debugForm,
      dsn: UIDefaultTheme.api.sentry_dsn,
      integrations: [
        new BrowserTracing({
          tracingOrigins: [
            'localhost',
            'dev.data.cmcapital.prosperita.io',
            'stage.data.cmcapital.prosperita.io',
            'data.cmcapital.prosperita.io',
          ],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],
      tracesSampleRate: 1.0,
    });

    Sentry.configureScope((scope) => {
      scope.setTag('environment', this.debugForm ? 'stage' : 'production');
      scope.setUser({
        id: String(this.authService.user?.id),
        email: this.authService.user?.email,
      });
    });

    this.getData();

    this.verifyMaintenance();

    window.onload = () => {
      this.loadingService.loading = false;
    };
  }

  setupKeyboardListener() {
    window.addEventListener('keydown', (event) => this.handleKeyPress(event));
  }

  handleKeyPress(event: KeyboardEvent) {
    const key = event.key.toLowerCase();

    if (key.length === 1 && key.match(/[a-z0-9]/)) {
      this.inputSequence += key;
    }
    if (this.inputSequence.length > 10) {
      this.inputSequence = this.inputSequence.slice(-10);
    }

    if (this.inputSequence === 'canetinha1') {
      this.fillButtonUnclocked = !this.fillButtonUnclocked;
      this.toastService.show(
        'info',
        `Canetinha ${this.fillButtonUnclocked ? 'liberada' : 'bloqueada'}`,
        `${
          this.fillButtonUnclocked
            ? 'Agora você pode usar a canetinha'
            : 'Agora você não pode usar a canetinha'
        }`
      );
    }
  }

  async verifyMaintenance() {
    try {
      const { maintence } = await this.apiService.get({
        route: 'api/maintenance',
        token: false,
      });

      this.maintence = maintence;
    } catch (error) {
      console.log(error);
    }
  }

  getData() {
    this.setThemeVariables();
    this.setDynamicFavicon();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.titleService.setTitle(data + ' | ' + UIDefaultTheme.brand.name);
        }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((data) => {
        this.storageService.set('last_route', data['url']);
      });

    fakerbr.locale = 'pt_BR';

    this.clearSiteData();

    this.getNationalities();

    if (
      window.location.href.includes('localhost')
      // || window.location.href.includes('dev')
      // || window.location.href.includes('stage')
    ) {
      this.debugForm = true;
    }

    if (window.location.href.includes('localhost')) {
      this.local = true;
    }

    this.signupService.fetchDataEvent.subscribe(() => {
      switch (this.authService.user.active_register?.register.type) {
        case 'PF':
          this.getComplementaryPFChoices();
          break;
        case 'PJ':
          this.getComplementaryPJChoices();
          break;
        default:
          this.getComplementaryPJChoices();
          break;
      }
    });
  }

  ngOnInit(): void {
    this.loadingService.loading = true;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;

        if (this.currentUrl == null) {
          this.previousUrl = this.storage.get('past_url');
        }

        this.currentUrl = event.url;
        this.storage.set('past_url', this.previousUrl);
        this.urlService.setPreviousUrl(this.previousUrl);
      });
  }

  // Set variables from ui theme to root css
  setThemeVariables() {
    Object.keys(UIDefaultTheme.pallete).forEach((key) => {
      document.documentElement.style.setProperty(
        `--${key}`,
        UIDefaultTheme.pallete[key]
      );
    });
  }

  setDynamicFavicon() {
    const link: HTMLLinkElement =
      document.querySelector('#favicon-ico') || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = UIDefaultTheme.brand.favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  fillForm() {
    this.signupService.fillFormEvent.emit();
  }

  async getComplementaryPFChoices() {
    const { choices_data }: SignUpChoices =
      await this.apiService.get<SignUpChoices>({
        route: 'api/registration/complementary_pf',
        token: true,
      });

    const mapped: { [Key: string]: SelectItem[] } = {};

    Object.entries(choices_data).forEach(([key, value]) => {
      mapped[key] = value.map(({ id, name, ...extra }: any) => {
        return {
          label: String(name),
          value: String(id),
          extra: extra,
        };
      });
    });

    const code_options = [
      {
        key: 'banks',
        code_key: 'bank_code',
      },
      { key: 'cnaes', code_key: 'code' },
    ];

    code_options.forEach((code) => {
      mapped[code.key] = mapped[code.key].map((item: any) => {
        return {
          ...item,
          label: `${item.extra[code.code_key]} - ${item.label}`,
        };
      });
    });

    this.signupService.signupChoices = mapped;
    this.signupService.signupData = choices_data;

    this.signupService.choicesFetchedEvent.emit();
    this.signupService.complementaryDataFetchedEvent.emit();
  }

  async getComplementaryPJChoices() {
    const { choices_data }: SignUpChoices =
      await this.apiService.get<SignUpChoices>({
        route: 'api/registration/complementary_pj',
        token: true,
      });

    const mapped: { [Key: string]: SelectItem[] } = {};

    Object.entries(choices_data).forEach(([key, value]) => {
      mapped[key] = value.map(({ id, name, ...extra }: any) => {
        return {
          label: String(name),
          value: String(id),
          extra: extra,
        };
      });
    });

    const code_options = [
      {
        key: 'banks',
        code_key: 'bank_code',
      },
      { key: 'cnaes', code_key: 'code' },
    ];

    code_options.forEach((code) => {
      mapped[code.key] = mapped[code.key].map((item: any) => {
        return {
          ...item,
          label: `${item.extra[code.code_key]} - ${item.label}`,
        };
      });
    });

    this.signupService.signupChoices = mapped;
    this.signupService.signupData = choices_data;

    this.signupService.choicesFetchedEvent.emit(choices_data);
    this.signupService.complementaryDataFetchedEvent.emit();
  }

  clearSiteData() {
    // TODO - verificar se ainda é necessário essa verificação,
    // pois esta causando um bug de redirecionamento quando um usuario loga, sai e loga novamente
    // ---------------------
    // const firstVisit = this.storageService.getVisited();
    // if (firstVisit == null) {
    //   this.storageService.clear();
    //   this.storageService.setVisited();
    //   window.location.reload();
    // }
  }

  clearChromeSiteData() {}

  async getNationalities() {
    try {
      const res = await this.apiService.get<RegistrationInfo>({
        route: 'api/registration/get_registration_info',
      });

      const {
        nationalities,
        roles,
        documents,
        sign_powers,
        signature_authorizations,
      } = res;

      const choices: SelectItem[] = nationalities.map((_nationality) => ({
        label: `${_nationality.country}`,
        value: `${_nationality.id}`,
      }));

      const documentsChoices: SelectItem[] = documents.map(
        (_document: any) => ({
          label: `${_document.name}`,
          value: `${_document.id}`,
        })
      );

      const signPowerChoices: SelectItem[] = sign_powers.map(
        (_signPower: any) => ({
          label: `${_signPower.name}`,
          value: `${_signPower.id}`,
        })
      );

      const signatureAuthorizationChoices: SelectItem[] =
        signature_authorizations.map((_signatureAuthorization: any) => ({
          label: `${_signatureAuthorization.name}`,
          value: `${_signatureAuthorization.id}`,
          extra: _signatureAuthorization.representative_type,
        }));

      this.signupService.setNationalityChoices(choices);
      this.signupService.setRoles(roles);
      this.signupService.documentsChoices = documentsChoices;
      this.signupService.signPowerChoices = signPowerChoices;
      this.signupService.signatureAuthorizationChoices =
        signatureAuthorizationChoices;
    } catch (error) {
      this.toastService.show(
        'error',
        'Erro',
        'Erro ao recuperar opções, tente novamente'
      );
    }
  }

  logoff() {
    this.authService.clearUser();

    this.router.navigate(['/login']);
  }
}
