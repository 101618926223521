import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-fund-approval-banking',
  templateUrl: './dashboard-fund-approval-banking.component.html',
  styleUrls: ['./dashboard-fund-approval-banking.component.scss'],
})
export class DashboardFundApprovalBankingComponent implements OnInit {
  zeroingFund: any = undefined;

  loading = true;
  data;

  constructor(
    private signupService: SignupService,
    public fundService: FundService,
    private api: ApiService,
    private route: ActivatedRoute,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    if (this.fundService.fundData.banking) {
      this.data = this.fundService.fundData.banking;
      this.loading = false;
      this.zeroingFund = this.fundService.fundData.banking.zeroing_automatic;
    } else {
      this.getFundData();
    }
  }

  getChoicesName(object: string, value: any) {
    const choices = this.signupService.signupChoices;

    const choiceObject = choices[object];

    if (choiceObject) {
      return choiceObject.find((_choice) => _choice.value == value);
    }

    return { label: '', value: '' };
  }

  async getFundData() {
    try {
      const data = await this.api.get({
        route: 'api/v2/approvals/version/',
        params: {
          fund: this.route.snapshot.params.id,
          tab: 'banking',
        },
        token: true,
      });

      const fund = { ...data };

      if (fund[0].fund && fund[0].fund.length === 0) {
        this.loading = false;
        return;
      }

      this.data = fund[0].fund[0];
      this.zeroingFund = this.data.zeroing_automatic;

      this.fundService.fundData = {
        ...this.fundService.fundData,
        banking: fund[0].fund[0],
      };
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        'Erro ao carregar dados bancários: ' + error
      );
    }

    this.loading = false;
  }
}
