<ng-container *ngIf="loadingContent">
    <div class="row">
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-8">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-2">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>

<form [formGroup]="form" *ngIf="!loadingContent">
    <!-- <div class="row">
        <div class="col-12 col-lg-8">
            <app-select label="Fundo de interesse" [group]="form"
                controlName="interest_fund" [items]="fundOptions">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div> -->

    <div class="row">
        <div class="col-12 col-lg-4">
            <app-masked-input [group]="form" controlName="document" label="CPF"
                placeholder="123.456.789-10" tabindex="1" mask="000.000.000-00"
                [readOnly]="!!invitedInfo.document">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-8">
            <app-input [group]="form" controlName="full_name" [maxLength]="60"
                label="Nome completo" placeholder="João Luis Barroso"
                [readOnly]="!!invitedInfo.name  || foundPerson">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-masked-input [group]="form" controlName="birth_date"
                mask="00/00/0000" label="Data de nascimento"
                placeholder="dd/mm/aaaa">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-4">
            <app-select [items]="signupService.choices().gender_choices"
                [group]="form" controlName="gender" label="Gênero"
                placeholder="Selecione">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-select [items]="signupService.choices().marital_choices"
                [group]="form" controlName="marital_status" label="Estado civil"
                placeholder="Selecione">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <ng-container
        *ngIf="form.controls.marital_status.value === '2' || form.controls.marital_status.value === '3' ">
        <div class="row">
            <div class="col-12 col-lg-4">
                <app-masked-input [group]="form" controlName="spouse_document"
                    label="CPF do cônjuge" placeholder="000.000.000-00"
                    mask="000.000.000-00">
                </app-masked-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-4">
                <app-masked-input [group]="form" controlName="spouse_name"
                    label="Nome do cônjuge" placeholder="Kátia Inácio da Silva"
                    mask="W*" [usePatterns]="true" [readOnly]="foundSpouse">
                </app-masked-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>


            <div class="col-12 col-lg-4">
                <app-select [items]="signupService.choices().marital_statutes"
                    [group]="form" controlName="marriage_bond"
                    label="Regime de casamento">
                </app-select>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

        </div>
    </ng-container>

    <div class="row">
        <div class="col-12 col-lg-6">
            <app-masked-input [group]="form" controlName="mother_name"
                label="Nome da mãe" placeholder="Luiza Pereira da Silva"
                mask="W*" [usePatterns]="true">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-6">
            <app-masked-input [group]="form" controlName="father_name"
                label="Nome do pai" placeholder="(opcional)" mask="W*"
                [usePatterns]="true">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-4">
            <app-select [items]="signupService.nationalityChocies"
                [group]="form" controlName="nationality" placeholder="Selecione"
                label="Nacionalidade">
            </app-select>
            <!--<app-select [items]="signupService.ufs_names" [group]="form"
                controlName="naturalness" label="Naturalidade" placeholder="UF">
            </app-select> -->
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-8">
            <app-select [items]="signupService.choices().document_types"
                [group]="form" controlName="type" label="Tipo de documento"
                placeholder="Selecione">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-4">
            <app-masked-input [group]="form" controlName="rg" type="text"
                label="Número do Documento" placeholder="123456789" mask="0*">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-select [items]="signupService.issuingAgencies" [group]="form"
                controlName="dispatching_agency" type="text"
                label="Órgão expedidor" autocapitalize="characters"
                placeholder="SSP" mask="W*">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-2">
            <app-select [items]="signupService.ufs" [group]="form"
                controlName="uf" label="UF" placeholder="SP">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-3">
            <app-masked-input [group]="form" controlName="expedition_date"
                mask="00/00/0000" label="Data expedição"
                placeholder="dd/mm/aaaa">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-2">
            <app-select [group]="form" controlName="cellphone_ddi"
                [items]="DDIOptions" label="DDI">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-masked-input [group]="form" controlName="cellphone_number"
                label="Celular" mask="(00) 00000-0000||(00) 0000-0000"
                placeholder="(00) 99887-7665">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-2">
            <app-select [group]="form" controlName="telephone_ddi"
                [items]="DDIOptions" label="DDI">
            </app-select>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-masked-input [group]="form" controlName="telephone_number"
                label="Telefone" mask="(00) 00000-0000||(00) 0000-0000"
                placeholder="(opcional)">
            </app-masked-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-4">
            <app-input label="E-mail" [group]="form" controlName="email"
                placeholder="john.doe@mail.com"
                [readOnly]="!!invitedInfo.email">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-input [group]="form" controlName="site" label="Site"
                placeholder="(opcional)">
            </app-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12 col-lg-4">
            <app-currency-input [group]="form" controlName="revenues"
                label="Rendimento dos últimos 12 meses">
            </app-currency-input>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>

    <div class="row" *ngIf="userRole !== 'provider'">
        <div class="col-12" *ngIf="userRole === 'assignor'">
            <app-radio [multiple]="true" formControlName="joint_obligation">
                Cessão com coobrigação
            </app-radio>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12">
            <app-radio [multiple]="true" formControlName="pep_relationship"
                [isChecked]="form.controls.pep_relationship.value">
                <div class="flex-1 d-flex flex-column">
                    <span class="option-title">
                        Possuo relacionamento com pessoa politicamente exposta
                    </span>
                </div>
            </app-radio>

            <ng-container *ngIf="form.controls.pep_relationship.value">
                <app-spacer [bottom]="24"></app-spacer>

                <div class="row">
                    <div class="offset-1 col-7">
                        <app-masked-input [group]="form"
                            controlName="pepRelationshipDocument"
                            label="CPF da pessoa" placeholder="123.456.789-01"
                            [readOnly]="foundRelationPerson"
                            mask="000.000.000-00">
                        </app-masked-input>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <div class="row">
                    <div class="offset-1 col-7">
                        <app-input [group]="form"
                            controlName="pepRelationshipName"
                            label="Nome da pessoa" [maxLength]="60"
                            placeholder="Rubens Gracindo Emiliano"></app-input>

                        <app-spacer [bottom]="24"></app-spacer>
                    </div>
                </div>

                <div class="row">
                    <div class="offset-1 col-7">
                        <app-input [group]="form" controlName="pepBond"
                            label="Vínculo" [maxLength]="20"
                            placeholder="Esposa, marido, filho, sócio, etc">
                        </app-input>
                    </div>
                </div>
            </ng-container>
            <app-spacer [bottom]="24"></app-spacer>
        </div>

        <div class="col-12">
            <app-radio [multiple]="true"
                formControlName="authorization_bacen_queries">
                Autorizo a {{'brand.name' | theme}}
                a realizar consultas sobre meus dados no BACEN
                <span *ngIf="form.controls.authorization_bacen_queries.errors"
                    class="required-field">*</span>
            </app-radio>
            <app-spacer [bottom]="24"></app-spacer>
        </div>
    </div>
</form>