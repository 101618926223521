<div class="row mb-4">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <ng-container *ngIf="loading">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-3 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loading">
                <div class="dash-details-card-body">
                    <ng-container *ngIf="data">
                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="Características comuns">
                                </app-label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    TIPO DE COTA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{getChoicesName('quota_type_choices',
                                    data.quota_types)?.label ?? '--'}}
                                </span>
                            </div>

                            <div class="col-4 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Valor da cota inicial do fundo
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.initial_quota | currency: 'BRL' ??
                                    '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="Limite mínimo para movimentação">
                                </app-label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    APLICAÇÃO INICIAL
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.min_initial_application |
                                    currency: 'BRL' ?? '--'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    APLICAÇÃO ADICIONAL
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.min_additional_application |
                                    currency: 'BRL' ?? '--'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    RESGATE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.min_redemption | currency:
                                    'BRL' ?? '--'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    PERMANÊNCIA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.min_permanence | currency:
                                    'BRL' ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="Aplicação">
                                </app-label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CONVERSÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.application_conversion}} dias
                                    {{getChoicesName('amortization_grace_period_choices',
                                    data.application_conversion_unit)?.label ??
                                    '--'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    liquidação
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.application_sale_off}} dias
                                    {{getChoicesName('amortization_grace_period_choices',
                                    data.application_sale_off_unit)?.label ??
                                    '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-12">
                                <app-label class="font-weight-bold"
                                    label="Resgate">
                                </app-label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CONVERSÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.rescue_conversion}} dias
                                    {{getChoicesName('amortization_grace_period_choices',
                                    data.rescue_conversion_unit)?.label ??
                                    '--'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    liquidação
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.rescue_sale_off}} dias
                                    {{getChoicesName('amortization_grace_period_choices',
                                    data.rescue_sale_off_unit)?.label ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>
                    </ng-container>

                    <app-empty-content [isVisible]="!data"
                        title="Nenhum dado de cotas"
                        description="Não foi encontrado nenhum dado de cotas nesse cadastro"
                        icon="/assets/images/forgot-password-icon2.png">
                    </app-empty-content>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="row" *ngIf="data">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <div class="dash-details-card-body ">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <app-label label="Cotas registradas">
                        </app-label>
                    </div>
                    <app-spacer [bottom]="8"></app-spacer>
                </div>

                <table class="bordered">
                    <thead>
                        <tr>
                            <th>Classe</th>
                            <th>Sub classe</th>
                            <th>Data da emissão</th>
                            <th>Encerramento da cota</th>
                            <th>Quantidade de cotas</th>
                            <th>Valor da cota</th>
                            <th>Saldo (qtde. cotas)</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            *ngFor="let item of data.quota_series; let i = index">
                            <td>{{getChoicesName('quotas_classes_choices',item.quota_class)?.label
                                ?? '--'}}
                            </td>
                            <td>A definir</td>
                            <td>{{item.quota_initial_date | date:'dd/MM/yyyy'}}
                            </td>
                            <td>{{item.quota_final_date | date:'dd/MM/yyyy'}}
                            </td>
                            <td>{{item.quota_quantity}}</td>
                            <td>{{item.quota_amount | currency:'BRL' }}</td>
                            <td>{{item.balance}}</td>
                            <td>{{item.quota_status === 'actived' ? 'Ativa' :
                                'Encerrada' }}</td>
                            <td>
                                <div class="dash-card-item-buttons">
                                    <button class="dash-item-button"
                                        (click)="handleOpenQuota(i)">
                                        <i class="fas fa-eye"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="showQuotaDetails" (onClose)="closeModal()"
    paddingBottom="30" title="Cota registrada">
    <div class="container" *ngIf="selectedQuota !==null">
        <div class="row">
            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    tipo de oferta de cota
                </span>

                <span class="dash-details-card-item-title">
                    {{getChoicesName('quota_offer_type_choices',quotas[selectedQuota].quota_offer_type)?.label
                    ?? '--'
                    }}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    benchmark
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].benchmark ?? '--'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    classe
                </span>

                <span class="dash-details-card-item-title">
                    {{getChoicesName('quotas_classes_choices',quotas[selectedQuota].quota_class)?.label
                    ?? '--'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    sub classe
                </span>

                <span class="dash-details-card-item-title">
                    <!-- {{quotas[selectedQuota].quota_order.label}} -->
                    A definir
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    emissão
                </span>

                <span class="dash-details-card-item-title">
                    {{getChoicesName('quotas_classes_order_choices',quotas[selectedQuota].emission)?.label
                    ?? '--'
                    }}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    quantidade inicial de cotas
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_quantity}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    valor cota inicial
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_amount | currency:'BRL'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    valor da série
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_serie_amount |
                    currency:'BRL'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    saldo (qtde. de cotas)
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].balance | currency:'BRL'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    status
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_status === 'actived' ? 'Ativa'
                    :'Encerrada'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>


            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    data emissão
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_initial_date |
                    date:'dd/MM/yyyy'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    data de registro na cvm
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_cvm_register_date |
                    date:'dd/MM/yyyy'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    data encerramento da oferta
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quote_offer_final_date |
                    date:'dd/MM/yyyy'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    data encerramento da cota
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].quota_final_date |
                    date:'dd/MM/yyyy'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <div class="d-flex align-items-center">
                    <app-label label="Série de cotas">
                    </app-label>
                </div>
                <app-spacer [bottom]="8"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    periodicidade da amortização
                </span>

                <span class="dash-details-card-item-title">
                    {{getChoicesName('amortization_frequency_choices',quotas[selectedQuota].amortization_frequency)?.label
                    ?? '--'
                    }}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    data de inicio da amortização
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].amortization_initial_date |
                    date:'dd/MM/yyyy'}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 col-md-6 d-flex flex-column">
                <span class="dash-details-card-item-subtitle">
                    amortização calculada sobre
                </span>

                <span class="dash-details-card-item-title">
                    {{getChoicesName('amortization_grace_period_choices',quotas[selectedQuota].amortization_grace_period)?.label
                    ?? '--'
                    }}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12 d-flex flex-column"
                *ngIf="quotas[selectedQuota].amortization_information">
                <span class="dash-details-card-item-subtitle">
                    informações adicionais sobre amortização
                </span>

                <span class="dash-details-card-item-title">
                    {{quotas[selectedQuota].amortization_information}}
                </span>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>
    </div>
</app-side-modal>