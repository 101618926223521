<ng-container *ngIf="loading">
    <div class="row">
        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-12">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>

        <div class="col-6">
            <ngx-skeleton-loader [theme]="{height: '40px'}">
            </ngx-skeleton-loader>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!loading">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-6">
                <app-currency-input label="Limite operacional" [group]="form"
                    controlName="operating_limit" placeholder="R$ 1.000,00">
                </app-currency-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-6">
                <app-select label="Risco" [items]="riskOptions" [group]="form"
                    controlName="risk" placeholder="Digite">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-select label="Enquadramento migrado Web Corp."
                    [items]="webCopOptions" [group]="form"
                    controlName="web_corp_migrated_framework"
                    placeholder="Digite">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input type="textarea" [group]="form"
                    controlName="observations" [rows]="4" label="Observações"
                    [noResize]="true" [maxLength]="300">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>
    </form>
</ng-container>