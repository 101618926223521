import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import UIDefaultTheme from 'src/themes';

import gitInfo from 'git-info';
import Utils from 'src/app/helpers/utils';
import { LoadingService } from 'src/app/services/Loading/loading.service';
import { permission } from 'src/app/data/permissions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form = this.formBuilder.group({
    username: [null, [Validators.required, Utils.validateCpfCnpj]],
  });

  valid: boolean = false;

  gitInfo = gitInfo;

  passwordForm = this.formBuilder.group({
    password: [null, [Validators.required, Validators.minLength(8)]],
  });

  newPasswordForm = this.formBuilder.group({
    newPassword: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        (control: FormControl) => {
          const password = control.value;

          if (!this.hasNumber(password)) return { noNumber: true };

          if (!this.hasLowerCase(password)) return { noLowerCase: true };

          if (!this.hasUpperCase(password)) return { noUpperCase: true };

          if (!this.hasSpecialCharacter(password))
            return { noSpecialCharacter: true };

          return null;
        },
      ],
    ],
  });
  forgotModal: boolean = false;
  codeModal: boolean = false;

  loading = false;

  role = 'shareholder';

  token: string | null = null;

  haveParams = false;

  debugForm = false;

  public UIDefaultTheme = UIDefaultTheme;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiService: ApiService,
    private storageService: StorageService,
    public authService: AuthService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private activedRoute: ActivatedRoute,
    public loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    if (
      window.location.href.includes('localhost') ||
      window.location.href.includes('dev') ||
      window.location.href.includes('stage')
    ) {
      this.debugForm = true;
    }

    this.token = this.route.snapshot.params.token;

    if (this.route.snapshot.queryParamMap.get('tkn')) {
      this.staffAccess();
    }

    this.route.data.subscribe((data) => {
      this.role = data.role ?? 'shareholder';
    });

    this.form.controls.username.valueChanges.subscribe((value) => {
      this.authService.forgotEmail = value;
    });
    /* this.form.controls.username.statusChanges.subscribe((valid) => {
      if (valid === 'VALID') {
        this.hideEmail();
      }
    }); */

    this.activedRoute.queryParams.subscribe((params) => {
      if (params.role && params.id) {
        this.haveParams = true;
      }
    });
  }

  /* hideEmail() {
    const email = this.form.controls.email.value;

    const emailArr = email.split('@');

    const firstLetters = emailArr[0].substring(0, 2);
    const lastLetters = emailArr[0].substring(
      emailArr[0].length - 2,
      emailArr[0].length
    );

    const remainingLetters = emailArr[0].length - 4;

    const emailMask = `${firstLetters}${'*'.repeat(
      remainingLetters
    )}${lastLetters}@${emailArr[1]}`;

    this.authService.hidedForgotEmail = emailMask;
  } */

  hidePhone() {
    const phone = this.storageService.getPreUser().phone;

    const phoneArr = phone.split('-');

    const firstLetters = phoneArr[0].substring(0, 2);
    const lastLetters = phoneArr[0].substring(
      phoneArr[0].length - 2,
      phoneArr[0].length
    );

    const remainingLetters = phoneArr[0].length - 4;

    const phoneMask = `${firstLetters}${'*'.repeat(
      remainingLetters
    )}${lastLetters}-${phoneArr[1]}`;

    this.storageService.setPreUser({ phone: phoneMask });
  }

  async validateUsername() {
    this.loading = true;

    const value = Utils.onlyNumbers(this.form.controls.username.value);
    const username = value ?? '';

    try {
      const res = await this.apiService.post<ValidateFieldResponse>({
        route: 'validate-username-exists/',

        body: { username },
      });

      if (res.exists) {
        this.valid = true;
        this.storageService.setPreUser({ username });
      } else {
        this.toastService.show(
          'error',
          'Ops...',
          'Parece que o seu usuário não foi encontrado'
        );
        this.form.controls.email.setErrors({ incorrect: true });
      }
    } catch (error) {}

    this.loading = false;
  }

  redirectUser(response: NewLoginResponse) {
    const redirect = this.route.snapshot.queryParamMap.get('redirect');

    if (redirect) {
      this.router.navigateByUrl(redirect);
      return;
    }

    if (response.is_staff) {
      this.router.navigateByUrl(`/app/dashboard`);
      return;
    }

    if (response.active_register) {
      if (response.active_register.register.trace_step) {
        const {
          active_register: {
            register: {
              trace_step: { step_display },
              role: { applicable },
              type,
            },
          },
        } = response;

        if (step_display === 'Cadastro') {
          this.router.navigateByUrl(
            `/signup/${applicable}/${type.toLowerCase()}`
          );
          return;
        }
      }
    }

    this.router.navigateByUrl(`/app/dashboard`);
    return;
  }

  async validatePassword() {
    this.loading = true;

    const value = Utils.onlyNumbers(this.form.controls.username.value);

    const username = value ?? '';

    const password = this.passwordForm.get('password')?.value ?? '';

    try {
      const { data } = await this.apiService.post<
        ApiResponse<NewLoginResponse>
      >({
        route: 'new-login/',
        body: { username, password },
      });

      if (!data.is_staff && !data.active_register) {
        this.toastService.show(
          'error',
          'Erro',
          'O seu cadastro se encontra em uma versão antiga, por favor, entre em contato com o suporte para atualizar seu cadastro.'
        );
        this.loading = false;
        this.form.controls.email.setErrors({ incorrect: true });
        this.form.controls.password.setErrors({ incorrect: true });
        return;
      }

      let permissionsArr =
        data.active_register.permissions_role &&
        data.active_register.permissions_role.permissions.length > 0
          ? data.active_register.permissions_role.permissions
          : permission;

      if (window.location.href.includes('dev')) {
        permissionsArr = permission;
      }

      this.storageService.setPermissionType(data.type_permission);

      this.authService.setToken(data.tokens.access);
      this.authService.setUser(data);

      if (data.active_register.register.role.applicable === 'representative') {
        const defaultPermissions: Permission[] = permission;

        permissionsArr = permissionsArr.concat(defaultPermissions);
      }

      this.authService.permissions = permissionsArr;
      this.storageService.set('permissions', permissionsArr, true);

      this.redirectUser(data);
    } catch (error: any) {
      console.warn(error);

      if (error.error && error.error.detail) {
        if (error.error.detail.includes('verified')) {
          this.storageService.setPreUser({ username: username });
          this.codeModal = true;

          await this.apiService.post({
            route: 'request-token/',
            body: {
              username: username,
              channel: 'all',
              reset: false,
            },
          });
        } else {
          this.toastService.show('error', 'Erro!', 'Senha incorreta');
          this.passwordForm.controls.password.setErrors({ incorrect: true });
        }
      } else {
        this.toastService.show('error', 'Erro!', 'Senha incorreta');
        this.passwordForm.controls.password.setErrors({ incorrect: true });
      }
    }

    this.loading = false;
  }

  openModal() {
    this.forgotModal = true;
  }

  closeModal() {
    this.forgotModal = false;
  }

  onValidateCode({ valid }: ValidateResponse) {
    if (valid) {
      this.toastService.show('info', 'Sucesso!', 'Conta validada com sucesso!');
    } else {
      this.toastService.show('error', 'Erro!', 'Código incorreto');
    }

    this.codeModal = false;
  }

  hasLowerCase(str: string) {
    return str.toUpperCase() !== str;
  }

  hasUpperCase(str: string) {
    return str.toLowerCase() !== str;
  }

  hasSpecialCharacter(str: string) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(str);
  }

  hasNumber(str: string) {
    return /\d/.test(str);
  }

  passwordStrength(): number[] {
    const password = this.newPasswordForm.get('newPassword')?.value ?? '';

    const strengthSteps = [];

    if (password !== '') strengthSteps.push(0);
    if (password.length >= 8) strengthSteps.push(1);
    if (this.hasNumber(password)) strengthSteps.push(2);
    if (this.hasLowerCase(password) && this.hasUpperCase(password))
      strengthSteps.push(3);
    if (this.hasSpecialCharacter(password)) strengthSteps.push(4);

    return strengthSteps;
  }

  passwordStrengthPercentage(): number {
    return (100 * this.passwordStrength().length) / 5;
  }

  passwordStrengthText(): string {
    const percentage = this.passwordStrengthPercentage();

    if (percentage > 60 && percentage < 90) {
      return 'Sua senha precisa melhorar';
    } else if (percentage >= 90) {
      return 'Sua senha parece bem segura';
    }

    return 'Sua senha está fraca';
  }

  async sendPassword() {
    const password = this.newPasswordForm.get('newPassword')?.value;

    try {
      const payload = {
        email: this.authService.forgotValue,
        token: this.authService.code,
        password,
      };

      await this.apiService.post({
        route: 'password-reset-complete/',
        body: payload,
      });

      this.openModal();
    } catch (error) {
      console.error(error);
    }
  }

  async staffAccess() {
    try {
      const { data } = await this.apiService.post<
        ApiResponse<NewLoginResponse>
      >({
        route: 'new-login/',
        body: { tkn: this.route.snapshot.queryParamMap.get('tkn') },
      });

      this.authService.setToken(data.tokens.access);
      this.authService.setUser(data);

      this.storageService.setPermissionType(data.type_permission);

      if (!data.active_register.permissions_role) {
        this.toastService.show('error', 'Erro!', 'Usuário sem permissões');
      } else {
        this.authService.permissions =
          data.active_register.permissions_role.permissions;
        this.storageService.set(
          'permissions',
          data.active_register.permissions_role.permissions,
          true
        );
      }

      this.router.navigateByUrl(`/app/dashboard`);
    } catch (error: any) {}
  }

  backtoAuth() {
    this.authService.setUserAuth('authStep', '0000');
  }
}
