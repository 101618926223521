<div class="row">
    <div class="col-12">
        <div class="dash-details-card card-padding-top">
            <ng-container *ngIf="loading">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-4 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-3 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loading">
                <div class="dash-details-card-body">
                    <ng-container *ngIf="data">
                        <div class="row">
                            <div class="col-12 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    ADMINISTRAÇÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.administration_fee ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-12 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CUSTÓDIA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.custody_fee ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-12 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CONTROLADORIA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.controlling_fee ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-12 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    ESCRITURAÇÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.bookkeeping_fee ?? '--'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    GERAL
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.general_fee |
                                    currency:'BRL'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    ESTRUTURAÇÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.structuring_fee |
                                    currency:'BRL'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    IMPLANTAÇÃO SISTÊMICA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.implantation_fee |
                                    currency:'BRL'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    GESTÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.management_fee |
                                    currency:'BRL'}}
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DISTRIBUIÇÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.distribution_fee |
                                    currency:'BRL' ??
                                    '--'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    VERIFICAÇÃO DE LASTRO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.check_fee |
                                    currency:'BRL'?? '--'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    AGENTE DE COBRANÇA
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.agent_fee |
                                    currency:'BRL' ?? '--'}}
                                </span>
                            </div>

                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Consultoria
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.consultancy_fee |
                                    currency:'BRL'?? '--'}}
                                </span>
                            </div>
                        </div>

                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="row">
                            <div class="col-2 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    TOTAL DE TAXAS
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.total_fee |
                                    currency:'BRL' ??
                                    '--'}}
                                </span>
                            </div>
                        </div>

                        <ng-container *ngIf="data.other_tax">
                            <app-spacer [bottom]="26"></app-spacer>
                            <div class="row">
                                <div class="col-12">
                                    <app-label class="font-weight-bold"
                                        label="Outras taxas">
                                    </app-label>
                                </div>
                            </div>
                            <app-spacer [bottom]="8"></app-spacer>

                            <div class="row">
                                <div class="col-2 d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        PERFORMANCE
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{data.performance_tax ?? '--'}}
                                    </span>
                                </div>
                            </div>
                            <app-spacer [bottom]="16"></app-spacer>

                            <div class="row">
                                <div class="col-2 d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        ENTRADA
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{data.entry_tax}}%
                                    </span>
                                </div>

                                <div class="col-2 d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        SAIDA
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{data.out_tax}}%
                                    </span>
                                </div>

                                <div class="col-3 d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        COBRANÇA DE OUTRAS SERÁ SOBRE
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{getChoicesName('charge_on_choices',
                                        data.charge_type)?.label ?? '--'}}
                                    </span>
                                </div>
                            </div>

                            <app-spacer [bottom]="16"></app-spacer>

                            <div class="row">
                                <div class="col-12 d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        DESCRIÇÃO
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{data.other_tax_description ??
                                        '--'}}
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <app-empty-content [isVisible]="!data"
                        title="Nenhuma informação de taxa encontrada"
                        description="Não foi possível encontrar informações de taxa para este fundo."
                        icon="/assets/images/forgot-password-icon2.png">
                    </app-empty-content>

                </div>
            </ng-container>
        </div>
    </div>
</div>