<div class="col-12" *ngIf="loading">
    <div class="row">
        <div class="col-12">
            <ngx-skeleton-loader [theme]="{
                        height: '244px',
                        margin:'0 0 24px'
                    }">
            </ngx-skeleton-loader>
        </div>
    </div>
</div>

<div class="row" *ngIf="!loading">
    <div class="col-12">
        <div class="dash-details-card">
            <app-empty-content [isVisible]="!data"
                title="Nenhum dado encontrado"
                description="Não foi encontrado nenhum dado de risco nesse cadastro"
                icon="/assets/images/forgot-password-icon2.png">
            </app-empty-content>

            <ng-container *ngIf="data">
                <div class="dash-details-card-header"></div>
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-3">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Limite operacional
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.operating_limit ?
                                    formatBrl(data.operating_limit) : '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-3">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Risco
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.risk ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-3">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Enquadramento migrado Web Corp.
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.web_corp_migrated_framework ?? '--'
                                    }}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-9">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Observações
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{data.observations && data.observations !==
                                    ' ' ? data.observations : '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>