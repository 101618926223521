import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import Utils from 'src/app/helpers/utils';
import { PersonIncomeOthersValues } from '../../signup-unified-person/signup-unified-person-income/signup-unified-person-income-others/signup-unified-person-income-others.component';
import { ApiService } from 'src/app/services/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

export type PersonIncomeRealtyValues = {
  type: string;
  current_value: string;
  address: {
    cep: string;
    public_place: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    uf: string;
    country: string;
  };
};

@Component({
  selector: 'app-dashboard-approval-income',
  templateUrl: './dashboard-approval-income.component.html',
  styleUrls: ['./dashboard-approval-income.component.scss'],
})
export class DashboardApprovalIncomeComponent implements OnInit {
  columns: TableColumn<any>[] = [
    { name: 'Tipo do imóvel', cell: (row) => row.type },
    {
      name: 'Endereço completo',
      cell: (row) => `
          ${
            row.address && row.address.cep
              ? ` <span>
                ${row.address.public_place}, ${row.address.number}, ${
                  row.address.complement ?? ''
                } ${row.address.district}
                </span>
                <span class="table-subtitle">${row.address.city}, ${
                  row.address.uf
                } — ${row.address.cep}</span>`
              : ''
          }
    
          `,
    },
    {
      name: 'Valor atual',
      cell: (row) => Utils.formatBrl(Number(row.current_value)),
    },
  ];

  othersColumns: any[] = [
    { name: 'Tipo do imóvel', cell: (row) => row.type },
    {
      name: 'Descrição',
      cell: (row) => row.description,
    },
    {
      name: 'Valor atual',
      cell: (row) => Utils.formatBrl(Number(row.current_value)),
    },
  ];

  properties: PersonIncomeRealtyValues[] = [];

  footerData: Partial<PersonIncomeRealtyValues>[] = [];

  others: Partial<PersonIncomeOthersValues>[] = [];
  isPj: boolean = false;

  othersFooterData: Partial<PersonIncomeOthersValues>[] = [];
  wage: any;
  loading = true;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.authService.approvalData.income) {
      this.properties = this.authService.approvalData.income.properties;
      this.others = this.authService.approvalData.income.other_assets;
      this.isPj = this.authService.approvalData.income.type === 'PJ';
      this.wage = this.authService.approvalData.income.wages[0];
      this.loading = false;

      this.setFooterData();
    } else {
      this.getApprovalData();
    }
  }

  async getApprovalData() {
    try {
      const req_params = {
        register: this.route.snapshot.params.id,
        tab: 'assets',
      };

      if (this.authService.approvalData.revision) {
        req_params['revision'] = this.authService.approvalData.revision;
        req_params['version'] = this.authService.approvalData.version;
      }

      const data = await this.api.get<any[]>({
        route: `api/v2/approvals/version/`,
        token: true,
        params: req_params,
      });

      this.authService.approvalData.income = data[0].register;

      this.properties = data[0].register.properties;
      this.others = data[0].register.other_assets;
      this.isPj = data[0].register.type === 'PJ';
      this.wage = data[0].register.wages[0];
      this.loading = false;

      this.setFooterData();
    } catch (error) {
      console.log(error);
    }
  }

  setFooterData() {
    const total = String(
      this.properties.reduce(
        (prev, curr) => Number(prev) + Number(curr.current_value ?? 0),
        0
      )
    );

    const othersTotal = String(
      this.others.reduce(
        (prev, curr) => Number(prev) + Number(curr.current_value ?? 0),
        0
      )
    );

    this.footerData = [
      {
        type: '<span><strong>Total declarado<strong></span>',

        current_value: total,
      },
    ];

    this.othersFooterData = [
      {
        type: '<span><strong>Total declarado</strong></span>',
        current_value: othersTotal,
      },
    ];
  }

  formatBrl(amount: number | string) {
    const formatted = Utils.formatBrl(Number(amount));

    return formatted;
  }

  //   setHistoryData() {
  //     this.properties =
  //       this.approval.history.Properties || this.approval.properties;
  //     this.others =
  //       this.approval.history.OtherAssets || this.approval.other_assets;
  //     this.wage = this.approval.history?.Wage[0] || this.approval?.wage[0];
  //   }
}
